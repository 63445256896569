import React from "react";

const OnboardingCategory = ({ onboardingData, handleShowOnboarding }) => {
  return (
    <div
      className="relative w-[427px] h-[427px] rounded-[2rem] overflow-hidden shadow-lg cursor-pointer"
      onClick={() => handleShowOnboarding()}
    >
      <img
        src={onboardingData.portada.url} // Use the image URL from onboardingData
        alt="Imagen"
        className="w-full h-full object-cover object-center"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black via-[#0000002d] to-black"></div>
      <div className="absolute top-0 left-0 h-full p-4">
        <div className="h-[45%]" />
        <div className="flex flex-col h-[55%] justify-between pb-4">
          <h3 className="text-white text-[28px] font-bold text-start">
            {onboardingData.titulo}
          </h3>
          <p className="text-white text-[18px] text-start">
            {onboardingData.descripcion}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnboardingCategory;
