import * as React from "react";
import { useState } from "react";
import AdminLayout from "../components/AdminLayout";
import { Table, Tag, Space, Button,Input, Select,DatePicker, Checkbox, message, InputNumber, Tooltip   } from "antd";
import { Row, Col } from "antd";
import {
  DeleteOutlined,
  StopOutlined,
  MobileOutlined,
  CaretDownOutlined,
  MinusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getReportB2B } from "../api/users";
import SCButton from "../components/SCButton";
import { navigate } from "gatsby";
import moment from "moment";
import {formatoFecha, downloadExcelFile, formatoFechaSearch , calcularEdad, addMounth, formatFechaB2B} from './../components/helper/utilidades';
import { IoFilterSharp } from "@react-icons/all-files/io5/IoFilterSharp";






const ReporteUso = () => {

  const [b2bData, setB2bData] = useState([]);
  const [b2DataFirst, setB2bDataFirst] = useState([])
  const [b2bReportData, setB2bReportData] = useState([]);
  const [showSuscripcionFilter, setShowSuscripcionFilter] = useState(false)
  const [showFechasFilter, setShowFechasFilter] = useState(false)
  const [actualizar, setActualizar] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const [loading, setLoading] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [checkedSuscripcion, setCheckedSuscripcion] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();

  const [filters, setFilters] = useState({
    fechas: {
      inicio:"",
      fin: "",
    },
    
    
  })

  const optionsSuscripcion = [
    // {label:"TRIAL", value:"TRIAL"},
    // {label:"ACTIVE", value:"ACTIVE"},
    {label:"PREMIUM", value:"PREMIUM"},
    {label:"FREE", value:"FREE"},
    {label:"SOCIO", value:"SOCIO"},
    
  ]

  const columns = [
    {
      title: "Suscripción",
      dataIndex: "suscripcion",
      key: "suscripcion",
      width: 120,
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a,b) =>{
        if(a.suscripcion !== undefined && a.suscripcion !== null){
          if(b.suscripcion !== undefined && b.suscripcion !== null){
            return a.suscripcion.localeCompare(b.suscripcion)
          } else{
            return a.suscripcion.localeCompare("Sin Respuesta")
          }
        }else{
          if(b.suscripcion !== undefined && b.suscripcion !== null){
            return "Sin Respuesta".localeCompare(b.suscripcion)
          }else{
            return "Sin Respuesta".localeCompare("Sin Respuesta")
          }
        }
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Nombre del usuario",
      dataIndex: "nombre",
      key: "nombre",
      width: 120,
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a,b) =>{
        if(a.nombre !== undefined && a.nombre !== null){
          if(b.nombre !== undefined && b.nombre !== null){
            return a.nombre.localeCompare(b.nombre)
          } else{
            return a.nombre.localeCompare("")
          }
        }else{
          if(b.nombre !== undefined && b.nombre !== null){
            return "".localeCompare(b.nombre)
          }else{
            return "".localeCompare("")
          }
        }
      },
      sortDirections: ['ascend' , 'descend'],
    },
  
    {
      title: "Apellido del usuario",
      dataIndex: "apellido",
      key: "apellido",
      width: 120,
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        if(a.apellido !== undefined && a.apellido !== null){
          if(b.apellido !== undefined && b.apellido !== null){
            return a.apellido.localeCompare(b.apellido)
          } else{
            return a.apellido.localeCompare("")
          }
        }else{
          if(b.apellido !== undefined && b.apellido !== null){
            return "".localeCompare(b.apellido)
          }else{
            return "".localeCompare("")
          }
        }
        },
        
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Empresa",
      dataIndex: "empresa",
      key: "empresa",
      width: 120,
      render: (text) => <p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        if (a.empresa !== undefined && a.empresa !== null) {
          if (b.empresa !== undefined && b.empresa !== null) {
            return a.empresa.localeCompare(b.empresa);
          } else {
            return a.empresa.localeCompare("");
          }
        } else {
          if (b.empresa !== undefined && b.empresa !== null) {
            return "".localeCompare(b.empresa);
          } else {
            return "".localeCompare("");
          }
        }
      },
      sortDirections: ["ascend", "descend"],
    },

    {
      title: "Codigo",
      dataIndex: "codigo",
      key: "codigo",
      width: 120,
      render: (text) => <p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        if (a.codigo !== undefined && a.codigo !== null) {
          if (b.codigo !== undefined && b.codigo !== null) {
            return a.codigo.localeCompare(b.codigo);
          } else {
            return a.codigo.localeCompare("");
          }
        } else {
          if (b.codigo !== undefined && b.codigo !== null) {
            return "".localeCompare(b.codigo);
          } else {
            return "".localeCompare("");
          }
        }
      },
      sortDirections: ["ascend", "descend"],
    },

  
    {
      title: "Número de accesos",
      dataIndex: "accesos",
      key: "accesos",
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        return a.accesos - b.accesos
      },
      sortDirections: ['ascend', 'descend'],
    },

    {
      title: "Contenidos Reproducido",
      dataIndex: "contenidoReproducido",
      key: "contenidoReproducido",
      width: 120,
      render: (text) =><p className="text-xs w-16">{text}</p>,
      sorter: (a, b) => {
       return a.contenidoReproducido - b.contenidoReproducido
      },
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Contenidos vistos",
      dataIndex: "contenidoVistos",
      key: "contenidoVistos",
      width: 170,
      showHeader: true,
      render: (text) =><Tooltip placement="topLeft" title={text} >
      <p className="text-xs info-cell">{text}</p>
    </Tooltip>,
      sorter: (a, b) => {
        if(a.contenidoVistos !== undefined && a.contenidoVistos !== null){
          if(b.contenidoVistos !== undefined && b.contenidoVistos !== null){
            return a.contenidoVistos.localeCompare(b.contenidoVistos)
          } else{
            return a.contenidoVistos.localeCompare("")
          }
        }else{
          if(b.contenidoVistos !== undefined && b.contenidoVistos!== null){
            return "".localeCompare(b.contenidoVistos)
          }else{
            return "".localeCompare("")
          }
        }
      },
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Tiempo de uso",
      dataIndex: "tiempoDeUso",
      key: "tiempoDeUso",
      render: (text) =><p className="text-xs">{Math.round(parseFloat( text))  + " Minutos"}</p>,
      sorter: (a, b) => Math.floor(parseFloat(a.tiempoDeUso)) - Math.floor(parseFloat( b.tiempoDeUso)),
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Fecha inicio",
      dataIndex: "fechaInicio",
      key: "fechaInicio",
      render: (text) =><p className="text-xs">{text !== "-" && text!== undefined ? formatoFecha(text): ""}</p>,
      sorter: (a, b) => {
        if (a.fechaInicio === null || b.fechaInicio === null) {
          return a.fechaInicio === null ? 1 : -1
        }
        if (a.fechaInicio === undefined || b.fechaInicio === undefined) {
          return a.fechaInicio === undefined ? 1 : -1
        }
        if (a.fechaInicio === "-" || b.fechaInicio === "-") {
          return a.fechaInicio === "-" ? 1 : -1
        }
        if (a.fechaInicio  && b.fechaInicio ) {
          return new Date(a.fechaInicio) - new Date(b.fechaInicio) 
        }
        if (a.fechaInicio ) {
          return -1
        }
        if (b.fechaInicio ) {
          return 1
        }
        return 0
      },
      // new Date(a.fechaInicio) - new Date(b.fechaInicio) 
    
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Fecha fin",
      dataIndex: "fechaFin",
      key: "fechaFin",
      render: (text) =><p className="text-xs">{text !== "-" && text!== undefined ? formatoFecha(text): ""}</p>,
      sorter: (a, b) => {
      if (a.fechaFin === null || b.fechaFin === null) {
        return a.fechaFin === null ? 1 : -1
      }
      if (a.fechaFin === undefined || b.fechaFin === undefined) {
        return a.fechaFin === undefined ? 1 : -1
      }
      if (a.fechaFin === "-" || b.fechaFin === "-") {
        return a.fechaFin === "-" ? 1 : -1
      }
      if (a.fechaFin  && b.fechaFin ) {
        return new Date(a.fechaFin) - new Date(b.fechaFin) 
      }
      if (a.fechaFin ) {
        return -1
      }
      if (b.fechaFin ) {
        return 1
      }
      return 0
    },
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "pasos o moves",
      dataIndex: ['subscription','fechaCancelacion'],
      key: "createdAtCancel",
      render: (text) =><p className="text-xs">{text}</p>,
      // sorter: (a, b) =>  ,
      // sortDirections: ['ascend', 'descend'],
    },
  
    
  
  ];

  React.useEffect(() => {
    // let firstDay = moment().startOf('month').toDate()
    // console.log(moment(firstDay).format("YYYY-MM-DD"))
    const data = { 
      startDate: moment().format("YYYY-MM-DD"), 
      endDate: moment().add(6, 'hours').add(1, 'day').format("YYYY-MM-DD"), 
    }
    console.log(data)
    getReportB2B(data)
      .then((response) => {
        console.log(response)
        setB2bData(response);
        setB2bReportData(response);
        setB2bDataFirst(response)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });

    //// cargar los usuarios
    // console.log("cargando")
  },[actualizar]);



 
  const handleSearch = (value) => {
    setValueSearch(value);
    if (value === '') {
      // Restablecer los datos de la tabla al estado original si la búsqueda está vacía
      setB2bReportData(b2bData);
    } else {
      // Filtrar la lista original y actualizar los datos de la tabla con el resultado
      let filter = b2bData.filter((item) => {
        return item.nombre?.toLowerCase().includes(value.toLowerCase()) ||
               item.empresa?.toLowerCase().includes(value.toLowerCase());
      });
      setB2bReportData(filter);
    }
  };
  
  

  const dataSource = b2bReportData.map((data ,  indexOf) =>( 
    {
    ...data,
    key:data.firebaseId !=="" ? data.firebaseId : indexOf,
  }
   ))


  const handleSelect = (record, selected) => {
    // console.log(record)
    // console.log(selected)
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.key]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.key);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const toggleSelectAll = () => {
  
    setSelectedRowKeys((keys) =>
      keys.length === b2bReportData.length ? [] : b2bReportData.map((r) => r.firebaseId)
    );
  
  
  };
  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < b2bReportData.length
      }
      onChange={toggleSelectAll}
    />
  );
  
  const rowSelection ={
    type:"checkbox",
    selectedRowKeys,
    fixed:true,
    //onChange: onSelectChange,
      onSelect: handleSelect,
      columnTitle: headerCheckbox,
   
  }

  const handleDataFilter = () =>{
    HideFiltros()
    setLoading(true)
    var filterData;
    if(filters.fechas.inicio==="" && filters.fechas.fin==="" && checkedSuscripcion.length === 0){
      messageApi.open({
        type: 'error',
        content: 'No se seleciono algún filtro',
      },2000);
      setLoading(false)
    } else if (filters.fechas.inicio==="" && filters.fechas.fin==="" && checkedSuscripcion.length > 0){
      filterData = b2bData.filter(item =>{
        if(item.suscripcion !==null){
          if(checkedSuscripcion.includes('SOCIO')){
            if(item.sportCityId){
              return item.sportCityId
            }else{
              return checkedSuscripcion.includes(item.suscripcion.toUpperCase())
            }
          }else{
            return checkedSuscripcion.includes(item.suscripcion.toUpperCase())
          }
         }
        
      })
      setB2bReportData(filterData);
        setLoading(false)
    }
    else{
      const data = { 
        startDate: moment(filters.fechas.inicio).format("YYYY-MM-DD"), 
        endDate: moment(filters.fechas.fin).add(6, 'hours').add(1, 'day').format("YYYY-MM-DD"), 
      }
     
      // console.log(data)
      getReportB2B(data)
      .then((response) => {
        setB2bData(response)
        filterData = response.filter(item =>{
          if(item.fechaInicio==="-" || item.fechaFin==="-"){
            return false
          }else{
            return true
          }
        })
        if(checkedSuscripcion.length > 0){
          filterData = filterData.filter(item =>{
            if(item.suscripcion !==null){
              if(checkedSuscripcion.includes('SOCIO')){
                if(item.sportCityId){
                  return item.sportCityId
                }else{
                  return checkedSuscripcion.includes(item.suscripcion.toUpperCase())
                }
              }else{
                return checkedSuscripcion.includes(item.suscripcion.toUpperCase())
              }
             }
          })
        }
        
        setB2bReportData(filterData);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });

       
      }
  //
  }


  const handleActualizar = () =>{
    
    console.log('actualizando...')
    setB2bData([])
    setB2bReportData([])
    setB2bDataFirst([])
    setLoading(true)
    setActualizar(!actualizar)
    handleClearFilter()

    
  }

  const HideFiltros = () =>{
    setShowFechasFilter(false)
    setShowSuscripcionFilter(false)
  }

  const handleClearFilter = () =>{
    setFilters({
      fechas: {
        inicio:"",
        fin: "",
      },

      
    });
    setCheckedSuscripcion([])
    HideFiltros()
    setValueSearch('')
    setB2bReportData(b2DataFirst)
    setB2bData(b2DataFirst)
  }

  const  handleDate = (value, filtro)  =>{

    if(filtro){
      setFilters({
        fechas: {
          inicio:value,
          fin: filters.fechas.fin ,
        },
      });
  } else{
    setFilters({
      fechas: {
        inicio:filters.fechas.inicio ,
        fin: value,
      },
    });
  }
  }

  const handleCheckboxChange =(checkedValues) =>{
    
    setCheckedSuscripcion(checkedValues)
  }
  const handleGenerateExcel = () =>{


    let key_id = 1;
    if (selectedRowKeys.length > 0) {
      const datafilter = b2bData.filter((items) => {
        return selectedRowKeys.includes(items.firebaseId);
      });
    
      const data = datafilter.map((value) => {
        const horasDiferencia = 6;
        let fechaInicioISO = "";
        let fechaFinISO = "";
      
        // Convertir la cadena de texto en un objeto de fecha y hora
        try {
          if (value.fechaInicio) {
            const fechaInicio = new Date(value.fechaInicio);
            if (!isNaN(fechaInicio)) {
              fechaInicio.setHours(fechaInicio.getHours() - horasDiferencia);
              if (fechaInicio.getHours() < 0) {
                fechaInicio.setDate(fechaInicio.getDate() - 1);
                fechaInicio.setHours(fechaInicio.getHours() + 24);
              }
              fechaInicioISO = fechaInicio.toISOString();
            }
          }
      
          if (value.fechaFin) {
            const fechaFin = new Date(value.fechaFin);
            if (!isNaN(fechaFin)) {
              fechaFin.setHours(fechaFin.getHours() - horasDiferencia);
              if (fechaFin.getHours() < 0) {
                fechaFin.setDate(fechaFin.getDate() - 1);
                fechaFin.setHours(fechaFin.getHours() + 24);
              }
              fechaFinISO = fechaFin.toISOString();
            }
          }
        } catch (error) {
          // Manejar cualquier excepción que ocurra al convertir fechas
          console.error("Error al convertir fechas:", error.message);
        }
      
        return {
          Key: key_id++,
          "Suscripción": value.suscripcion || "",
          Nombre: value.nombre || "",
          Apellido: value.apellido || "",
          "Empresa": value.empresa || "",
          "Código": value.codigo || "",
          "Número de accesos": value.accesos || 0,
          "Contenido reproducido": value.contenidoReproducido || 0,
          "Contenidos vistos": value.contenidoVistos || "",
          "Tiempo de uso": Math.round(parseFloat(value.tiempoDeUso)) + " Minutos",
          "Fecha inicio": fechaInicioISO,
          "Fecha fin": fechaFinISO,
        };
      });
      
     downloadExcelFile(data, 'ReporteDeUso')
     
    }else{
      messageApi.open({
        type: 'error',
        content: 'No tienes ningún registro selecionado.',
        duration:2,
      });
    }
  }

  return (
    <AdminLayout>
    {contextHolder}
    {/* {console.log(checkedSuscripcion)} */}
      <Row justify="start">
        <Col span={12} sm={18} md={12} lg={8}>
          <h3 className="mb-8 text-sc-black font-bold text-[24px] text-left md:text-[32px]">
            Reporte de Uso
          </h3>
        </Col>
        <Col span={12} sm={6} md={12} lg={16}>
          <Row justify="end">
            <Col className="mr-2 mt-1 xs:mt-0 sm:mt-0 md:mt-0 lg:mt-0">
              <SCButton className="text-sm rounded" onClick={handleGenerateExcel}>Exportar a Excel</SCButton>
            </Col>
            <Col className="mt-1 xs:mt-0 sm:mt-0 md:mt-0 lg:mt-0">
              <SCButton className="text-sm  rounded" onClick={handleActualizar}>Actualizar</SCButton>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="start">
        <Col xs={12} sm={12} md={8} lg={7}>
          <div className="w-auto ml-2 mr-2">
            <Input
              placeholder="Buscar..."
              allowClear
              value={valueSearch}
              onChange={(e)=>handleSearch(e.target.value)}
              style={{
                borderRadius:"10px"
              }}
              suffix ={<SearchOutlined />}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2 " >
        <Col xs={24} sm={24} md={14} lg={14}  >
          <Row >
          <Col span={12}>
            <div className="bg-gray-50  mt-2  ml-2 mr-2  cursor-pointer h-12 sm:h-11 md:h-12 xl:h-10 lg:h-14 w-auto shadow-lg border flex flex-row justify-between border-slate-400 rounded-xl" onClick={()=>setShowSuscripcionFilter(!showSuscripcionFilter)}>
                <div className=" ml-4 mt-1 " >
                  <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Suscripción: <b className ="md:text-[12px]">{
                    checkedSuscripcion.length > 0 ? 
                    checkedSuscripcion.map((dato, index)=>{
                      if(checkedSuscripcion.length === index+1 ){
                        return dato
                      }else{
                        return dato+', '
                      }
                      }) : 
                    "Seleccionar"}</b></p>
                </div>
                <div className=" mr-4  ">
                  <CaretDownOutlined />
                </div>
            </div>
            {showSuscripcionFilter? 
              (
                <div className=" absolute z-40 -mt-4 mb-4 mr-2 ml-2 w-full     ">
                  <div className=" flex flex-col justify-start mt-1 mr-4 border-b border-l border-r rounded-b-xl border-slate-400 bg-gray-50">
                    <div className="w-full border-t mt-2  border-slate-300" >
                     
                      <Checkbox.Group onChange={handleCheckboxChange} value={checkedSuscripcion} className="flex flex-col justify-center  w-full " >
                      {optionsSuscripcion.map((item) => (
                          <Checkbox className=" w-10/12 px-4  justify-start hover:bg-sc-primary hover:opacity-90" key={item.value} value={item.value}>
                            {item.label}
                          </Checkbox>
                        
                      ))}
                      </Checkbox.Group>
                    </div>
                  </div>
                </div>
                

              ):null}
              </Col>
            
          <Col span={12}>
            <div className="bg-gray-50  mt-2  ml-2 mr-2  cursor-pointer h-12 sm:h-11 md:h-12 xl:h-10 lg:h-14 w-auto shadow-lg border flex flex-row justify-between border-slate-400 rounded-xl" onClick={()=>setShowFechasFilter(!showFechasFilter)}>
              <div className=" ml-4 mt-1 " >
                <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Fechas: </p>
                <p className="text-xs text-left -mt-3 mb-1 text-[10px] sm:text-[12px] md:text-[12px]">
                  <b className ={ showFechasFilter ? 'opacity-0' : '' }>
                    {filters.fechas.inicio === "" && filters.fechas.fin==="" ? "Selecionar": `${formatoFecha(moment(filters.fechas.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechas.fin)).replaceAll('-','/')}`}
                  </b>
                </p>
              </div>
              <div className="mr-4  ">
                <CaretDownOutlined />
              </div>
            </div>
            {showFechasFilter ?
              (
                <div className="absolute z-20 -mt-4 mb-4 ml-2 md:-mt-2 md:pt-1 mr-2 w-full">
                  <div className=" mr-4 border-b border-l border-r -mt-2 border-gray-400  rounded-b-xl bg-gray-50   ">
                  <p className="text-xs text-left mt-1 ml-4 mb-0 md:-mt-4 lg:-mt-4 text-[10px] sm:text-[12px] md:text-[12px] ">
                  <b className ="md:text-[12px]">{filters.fechas.inicio === "" && filters.fechas.fin==="" ?
                      "Selecionar":
                      `${formatoFecha(moment(filters.fechas.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechas.fin)).replaceAll('-','/')}`}</b>
                  </p>
                    <div className=" flex justify-around border-t mt-0 pt-2 border-slate-300">
                      <div className="w-1/3 ">
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Desde:</p>
                      </div>
                      <div className="w-1/3 " >
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Hasta:</p>
                      </div>
                    </div>
                    <div>
                    <div className="flex justify-around p-2 -mt-4 mb-2">
                      <div>
                        <DatePicker 
                          onChange={(value)=>handleDate(value, true)}
                          suffixIcon={""}
                          placeholder={"Seleccionar"}
                          format={"DD/MM/YYYY"}
                          style={{
                            borderRadius:"10px",
                            backgroundColor:"#DADADA ",
                            
                          }}
                          />
                      </div>
                      <div>
                        <MinusOutlined />
                      </div>
                      <div >
                        <DatePicker 
                        onChange={(value)=>handleDate(value, false)}
                        suffixIcon={""}
                        placeholder={"Seleccionar"}
                        format={"DD/MM/YYYY"}
                        style={{
                          borderRadius:"10px",
                          backgroundColor:"#DADADA ",
                          }}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
              :null}
          </Col>
          
          </Row>
        </Col>
        
        <Col xs={24} sm={24} md={10} lg={10}>
          <div className="flex w-full    content-center">
            <div className="bg-gray-50  border shadow-lg border-slate-300 h-10  cursor-pointer ml-4z mt-2 w-auto rounded-xl flex justify-around" onClick={()=>{handleDataFilter()}}>
              <div className="mt-2 ml-2  md:ml-5 lg:ml-2 ">
                <IoFilterSharp size={18}/>
              </div>
              <div className="mt-2 ml-2 mr-8 text-sm text-[10px] sm:text-[12px] md:text-[14px]">
                <p>Filtrar</p>
              </div>
            </div>

            <div className="bg-gray-50  border shadow-lg border-slate-300 h-10  cursor-pointer ml-2 mt-2 w-auto rounded-xl flex justify-around" onClick={()=>{handleClearFilter()}}>
              {/* <div className="mt-2 ml-2 md:ml-5 lg:ml-2 ">
                <IoFilterSharp size={18}/>
              </div> */}
              <div className="mt-2 ml-2 lg:ml-4 mr-8 text-xs text-[10px] sm:text-[12px] md:text-[14px]">
                <p>Reset Filtro</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      
      <Table
        className="mt-4 cursor-pointer"
        rowSelection={rowSelection}
        dataSource={dataSource}
        columns={columns}
        size="small"
        rowKey = {(record)=> record.key}
        loading={loading}
        scroll ={{
          x:1300,
        }}
        pagination={{showSizeChanger: true}}
      />
    </AdminLayout>
  );
};

export default ReporteUso;
