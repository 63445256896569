import { message } from "antd";
import { serverUri } from "./serverUri";

const createNotification = (data, authToken) => {
  console.log("data", JSON.stringify(data));
  return fetch(`${serverUri}/notifications`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Pregunta creada con éxito", data);
      return true;
    })
    .catch((error) => {
      console.error("Error al crear la pregunta:", error);
      message.error("Ocurrió un error, inténtalo de nuevo");
      return false;
    });
};

// Nuevo método para obtener todas las notificaciones
const getNotifications = async (authToken) => {
  try {
    const response = await fetch(`${serverUri}/notifications?_limit=1000`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.json();
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error("Error al obtener las notificaciones:", error);
    message.error("Ocurrió un error, inténtalo de nuevo");
    return [];
  }
};

const updateNotification = (id, data, authToken) => {
  return fetch(`${serverUri}/notifications/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return true;
    })
    .then((data) => {
      return true;
    })
    .catch((error) => {
      message.error("Ocurrió un error, inténtalo de nuevo");
      console.log("Error al actualizar: ", error);
      return false;
    });
};

const deleteNotification = (id, authToken) => {
  return fetch(`${serverUri}/notifications/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      console.log("Respuesta eliminada con éxito", data);
      return data;
    })
    .catch((error) => {
      message.error("Ocurrió un error, inténtalo de nuevo");
      console.log("Error al eliminar la respuesta: ", error);
      return false;
    });
};

const searchContents = (data, authToken) => {
  return fetch(`${serverUri}/cards/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error al crear la pregunta:", error);
      message.error("Ocurrió un error, inténtalo de nuevo");
    });
};

export {
  createNotification,
  getNotifications,
  deleteNotification,
  updateNotification,
  searchContents,
};
