import * as React from "react";
import { useState } from "react";
import AdminLayout from "../components/AdminLayout";
import { Table, Tag, Space, Button,Input, Select,DatePicker, Checkbox, message, InputNumber, Switch  } from "antd";
import { Row, Col } from "antd";
import {
  DeleteOutlined,
  StopOutlined,
  MobileOutlined,
  CaretDownOutlined,
  MinusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getReportSuscriptores } from "../api/users";
import SCButton from "../components/SCButton";
import { navigate } from "gatsby";
import moment from "moment";
import {formatoFecha, downloadExcelFile, formatoFechaSearch , calcularEdad, addMounth, formatMesActual} from './../components/helper/utilidades';
import { IoFilterSharp } from "@react-icons/all-files/io5/IoFilterSharp";






const ReporteSuscriptores = () => {

  const [usersData, setUsersData] = useState([]);
  const [usersReportData, setUsersReportData] = useState([]);
  const [usersReportDataFirst, setUsersReportDataFirst] = useState([]);
  const [showFilterStatus, setShowFilterStatus] = useState(false)
  const [filterSearch, setFilterSearch] = useState("")
  const [showEdadFilter, setShowEdadFilter] = useState(false)
  const [showGeneroFilter, setShowGeneroFilter] = useState(false)
  const [showEstatusFilter, setShowEstatusFilter] = useState(false)
  const [showMembresiaFilter, setShowMembresiaFilter] = useState(false)
  const [showSuscripcionFilter, setShowSuscripcionFilter] = useState(false)
  const [showCancelacionFilter, setShowCancelacionFilter] = useState(false)
  const [showReactivacionFilter, setShowReactivacionFilter] = useState(false)
  const [showVencimientoFilter, setShowVencimientoFilter] = useState(false)
  const [actualizar, setActualizar] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const [loading, setLoading] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [messageApi, contextHolder] = message.useMessage();

  const [filters, setFilters] = useState({
    edad: {
      inicio:"",
      fin: "",
    },
    genero: '',
    estado: '',
    tipoMembresia: '',
    fechaSuscription: {
      inicio:"",
      fin: "",
    },
    fechaCancelacion: {
      inicio:"",
      fin: "",
    },
    fechaReactivacion: {
      inicio:"",
      fin: "",
    },
    fechaVencimiento: {
      inicio:"",
      fin: "",
    },
  })

  const columns = [
    {
      title: "Nombre del usuario",
      dataIndex: ['user','nombre'],
      key: "nombre",
      width: 100,
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a,b) =>{
        if(a.user.nombre !== undefined && a.user.nombre !== null){
          if(b.user.nombre !== undefined && b.user.nombre !== null){
            return a.user.nombre.localeCompare(b.user.nombre)
          } else{
            return a.user.nombre.localeCompare("Sin Respuesta")
          }
        }else{
          if(b.user.nombre !== undefined && b.user.nombre !== null){
            return "Sin Respuesta".localeCompare(b.user.apellido)
          }else{
            return "Sin Respuesta".localeCompare("Sin Respuesta")
          }
        }
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Apellido del usuario",
      dataIndex: ['user','apellido'],
      key: "apellido",
      width: 100,
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        if(a.user.apellido !== undefined & a.user.apellido !==null){
          if(b.user.apellido !== undefined & b.user.apellido!==null){
            return a.user.apellido.localeCompare(b.user.apellido)
          }else{
            return a.user.apellido.localeCompare("Sin Respuesta")
          }
        }else{
          if(b.user.apellido !== undefined & b.user.apellido!==null){
            return "Sin respuesta".localeCompare(b.user.apellido)
          }else{
            return "Sin respuesta".localeCompare("Sin respuesta")
          }
        }
      },
      sortDirections: ['ascend' , 'descend'],
    },
  
    {
      title: "Edad",
      dataIndex: ['user','fechaNacimiento'],
      key: "fechaNacimiento",
      width: 100,
      render: (text) =><p className="text-xs">{calcularEdad(text)}</p>,
      sorter: (a, b) => {
        if (calcularEdad(a.user.fechaNacimiento)  > calcularEdad(b.user.fechaNacimiento) ){
          return 1;
        }
        if (calcularEdad(b.user.fechaNacimiento) > calcularEdad(a.user.fechaNacimiento) ) {
          return -1;
        }
        return 0;
        },
        
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Género",
      dataIndex: ['user','sexo'],
      key: "sexo",
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        if (a.user.sexo == null || a.user.sexo === '') {
          return 1;
        }
        if (b.user.sexo == null || b.user.sexo === '') {
          return -1;
        }
        if (a.user.sexo === undefined) {
          return 1;
        }
        if (b.user.sexo === undefined) {
          return -1;
        }
        // Después, se ordenan los elementos restantes
        if (a.user.sexo.toLowerCase() > b.user.sexo.toLowerCase()) {
          return 1;
        }
        if (a.user.sexo.toLowerCase() < b.user.sexo.toLowerCase()) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Número de usuario [MoSo ID]",
      dataIndex: ['user','mosoReference'],
      key: "mosoReference",
      width: 100,
      render: (text) =><p className="text-xs w-16">{text}</p>,
      sorter: (a, b) => {
        if (a.user.mosoReference === undefined || a.user.mosoReference === null) {
          return 1;
        }
        if (b.user.mosoReference === undefined || b.user.mosoReference === null) {
          return -1;
        }
        return a.user.mosoReference.localeCompare(b.user.mosoReference)},
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Estatus",
      dataIndex: ['subscription','status'],
      key: "status",
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a, b) => a.subscription.status.localeCompare(b.subscription.status),
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Tipo de membresía SHIFT",
      dataIndex: ['user','suscription'],
      key: "suscription",
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a, b) => a.user.suscription.localeCompare(b.user.suscription),
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Fecha de suscripción",
      dataIndex: ['subscription','createdAt'],
      key: "createdAt",
      render: (text) =><p className="text-xs">{formatoFecha(text)}</p>,
      sorter: (a, b) => new Date(a.subscription.createdAt) - new Date(b.subscription.createdAt) ,
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Fecha de cancelación",
      dataIndex: ['subscription','fechaCancelacion'],
      key: "createdAtCancel",
      render: (text) =><p className="text-xs">{text !==null && text!== undefined ? formatoFecha(text):""}</p>,
      sorter: (a, b) => new Date(a.subscription.fechaCancelacion) - new Date(b.subscription.fechaCancelacion) ,
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Fecha de reactivación",
      dataIndex: ['subscription','fechaReactivacion'],
      key: "createdAtReactivacion",
      render: (text) =><p className="text-xs">{text !== null && text!== undefined ? formatoFecha(text): ""}</p>,
      sorter: (a, b) => new Date(a.subscription.fechaReactivacion) - new Date(b.subscription.fechaReactivacion) ,
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Fecha de vencimiento",
      dataIndex: ['subscription','expiracionPrueba'],
      key: "updatedAtVencimiento",
      render: (text) =><p className="text-xs">{text !== null && text!== undefined ? formatoFecha(text): ""}</p>,
      sorter: (a, b) => new Date(a.subscription.expiracionPrueba) - new Date(b.subscription.expiracionPrueba) ,
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: "Código promocional",
      dataIndex: ['transaction','codigo'],
      key: "codigo",
      render: (text) =><p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        if (a.transaction === undefined || a.transaction === null) {
          return 1;
        }
        if (b.transaction === undefined || b.transaction === null) {
          return -1;
        }
        return a.transaction.codigo.localeCompare(b.transaction.codigo);
      },
      sortDirections: ['ascend', 'descend'],
    },
  
  ];

  React.useEffect(() => {
    const data = {
      startDate: moment().startOf('month').format("YYYY-MM-DD"), 
      endDate: moment().add(2 ,'day').format("YYYY-MM-DD"), 
    }
    getReportSuscriptores(data)
      .then((response) => {
        setUsersData(response);
        setUsersReportDataFirst(response);
        setUsersReportData(response);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });

    //// cargar los usuarios
  },[actualizar]);

 

  const handleSearch = (value)=>{
    console.log(value)
    setValueSearch(value)
    let filter = usersData.filter(item => {
      if(item.user.nombre !==undefined){
        return item.user.nombre.toLowerCase().includes(value.toLowerCase());
      }
    })
    setUsersReportData(filter);
  }

  const dataSource = usersReportData.map((data) => {
    // Obtiene el estatus del usuario
    const status = data.subscription.status.toLowerCase();
  
    // Inicializa las fechas de cancelación y reactivación en blanco
    let fechaCancelacion = null;
    let fechaReactivacion = null;
  
    // Verifica el estatus y actualiza las fechas según corresponda
    if (status === "active") {
      fechaReactivacion = data.subscription.updatedAt;
    } else if (status === "cancelled") {
      fechaCancelacion = data.subscription.updatedAt;
    }
  
    // Devuelve un nuevo objeto con las fechas actualizadas
    return {
      ...data,
      key: data.user.id,
      subscription: {
        ...data.subscription,
        fechaCancelacion,
        fechaReactivacion,
      },
    };
  });
  
   console.log(dataSource)

  const handleEdadFilter = (value, filtro) =>{

    if(filtro === 0){
      setFilters({
        ...filters,
        edad: {
          inicio: value,
          fin: filters.edad.fin,
        }
      });
    }else{
      setFilters({
        ...filters,
        edad: {
          inicio:filters.edad.inicio,
          fin: value,
        }
      });
    }
    
    //console.log(value,filtro)
  }

  const handleFechaSuscription = (value, filtro) =>{

    if(filtro){
      setFilters({
        ...filters,
        fechaSuscription: {
          inicio: value,
          fin: filters.fechaSuscription.fin,
        }
      });
    }else{
      setFilters({
        ...filters,
        fechaSuscription: {
          inicio:filters.fechaSuscription.inicio,
          fin: value,
        }
      });
    }

  }

  const handleChangeGenero = (value) =>{
    setFilters({
      ...filters,
      genero:value,
    })
  }

  const handleDateCancel = (value, filtro) =>{
    
    if(filtro){
      setFilters({
        ...filters,
        fechaCancelacion: {
          inicio: value,
          fin: filters.fechaCancelacion.fin,
        }
      });
    }else{
      setFilters({
        ...filters,
        fechaCancelacion: {
          inicio:filters.fechaCancelacion.inicio,
          fin: value,
        }
      });
    }
  }

  const handleChangeStatus = (value) =>{
    setFilters({
      ...filters,
      estado:value,
    })
  }

  const handleDateReactive = (value, filtro) =>{
    
    if(filtro){
      setFilters({
        ...filters,
        fechaReactivacion: {
          inicio: value,
          fin: filters.fechaReactivacion.fin,
        }
      });
    }else{
      setFilters({
        ...filters,
        fechaReactivacion: {
          inicio:filters.fechaReactivacion.inicio,
          fin: value,
        }
      });
    }

  }

  const handleChangeMembresia = (value) =>{
    setFilters({
      ...filters,
      tipoMembresia:value,
    })
  }

  const handleDateVencimiento = (value, filtro) =>{
    
    if(filtro){
      setFilters({
        ...filters,
        fechaVencimiento: {
          inicio: value,
          fin: filters.fechaVencimiento.fin,
        }
      });
    }else{
      setFilters({
        ...filters,
        fechaVencimiento: {
          inicio:filters.fechaVencimiento.inicio,
          fin: value,
        }
      });
    }

  }
  const handleSelect = (record, selected) => {
    // console.log(record)
    // console.log(selected)
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.key]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.key);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const toggleSelectAll = () => {
  
    setSelectedRowKeys((keys) =>
      keys.length === usersReportData.length ? [] : usersReportData.map((r) => r.user.id)
    );
  
  
  };
  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < usersReportData.length
      }
      onChange={toggleSelectAll}
    />
  );
  
  const rowSelection ={
    type:"checkbox",
    selectedRowKeys,
    fixed:true,
    //onChange: onSelectChange,
      onSelect: handleSelect,
      columnTitle: headerCheckbox,
   
  }

  const handleDataFilter = () =>{
    HideFiltros()
    if(filters.genero==="" && filters.tipoMembresia==="" && 
      filters.estado==="" && filters.edad.inicio==="" && filters.edad.fin ==="" &&  
      filters.fechaSuscription.inicio==="" && filters.fechaSuscription.fin ==="" && 
      filters.fechaCancelacion.inicio==="" && filters.fechaCancelacion.fin ==="" &&  
      filters.fechaReactivacion.inicio==="" && filters.fechaReactivacion.fin ==="" && 
      filters.fechaVencimiento.inicio==="" && filters.fechaVencimiento.fin ===""){
        messageApi.open({
          type: 'error',
          content: 'No se seleciono algún filtro',
        },2000);

      }else{
        
        setLoading(true)
        if(filters.fechaSuscription.inicio !=="" && filters.fechaSuscription.fin!==""){
          const data = {
            // startDate: moment().startOf('month').format("YYYY-MM-DD"), 
            startDate: moment(filters.fechaSuscription.inicio).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(filters.fechaSuscription.fin).endOf('day').format("YYYY-MM-DD HH:mm:ss"),            
          }
          console.log(data)
          getReportSuscriptores(data)
            .then((response) => {
              setUsersData(response)
              filterDataWithFilter(response)
            })
            .catch((error) => {
              console.log(error);
            });
        }else{
          filterDataWithFilter(usersData)
        }
      }
      
   
   
  }
  //
  const filterDataWithFilter = (data) =>{

 
    let  filterData = data.filter((item) =>{
      switch(true){
        case filters.edad.inicio!=="" && filters.edad.fin !==""  && !(filters.edad.inicio <= calcularEdad(item.user.fechaNacimiento) ):
          return false;

        case filters.estado!=="" && item.subscription.status.toLowerCase() !== filters.estado.toLowerCase() :
          return false;

        case filters.genero!=="" && item.user.sexo !== null && item.user.sexo.toLowerCase() !== filters.genero.toLowerCase() || item.user.sexo ===null :
          return false;
        
        case filters.tipoMembresia!=="" && item.user.suscription.toLowerCase() !== filters.tipoMembresia.toLowerCase()  :
          return false;

        case filters.fechaCancelacion.inicio!=="" && filters.fechaCancelacion.fin !=="" &&  item.subscription ===undefined && item.subscription.fechaCancelacion ===undefined && !(moment(formatoFechaSearch(item.susbcription.fechaCancelacion)).isBetween(moment(filters.fechaCancelacion.inicio).format("YYYY-MM-DD"), moment(filters.fechaCancelacion.fin).format("YYYY-MM-DD"), 'day', '[]'))  :
          return false;
  
        case filters.fechaReactivacion.inicio!=="" && filters.fechaReactivacion.fin !=="" && item.subscription ===undefined && item.subscription.fechaReactivacion ===undefined && !(moment(formatoFechaSearch(addMounth(item.subcription.fechaReactivacion))).isBetween(moment(filters.fechaReactivacion.inicio).format("YYYY-MM-DD"), moment(filters.fechaReactivacion.fin).format("YYYY-MM-DD"), 'day', '[]')) :
          return false;
          
        case filters.fechaVencimiento.inicio!=="" && filters.fechaVencimiento.fin !=="" && !(moment(formatoFechaSearch(item.subscription.expiracionPrueba)).isBetween(moment(filters.fechaVencimiento.inicio).format("YYYY-MM-DD"), moment(filters.fechaVencimiento.fin).format("YYYY-MM-DD"), 'day', '[]')) :
          return false;
        
        default :
          return true;
      }
      
    });
    setUsersReportData(filterData)
    setLoading(false)
  }
  const handleActualizar = () =>{
    setUsersData([])
    setUsersReportData([])
    setUsersReportDataFirst([])
    setLoading(true)
    setActualizar(!actualizar)
    handleClearFilter()

    
  }

  const HideFiltros = () =>{
    setShowCancelacionFilter(false)
    setShowEdadFilter(false)
    setShowGeneroFilter(false)
    setShowEstatusFilter(false)
    setShowMembresiaFilter(false)
    setShowReactivacionFilter(false)
    setShowSuscripcionFilter(false)
    setShowVencimientoFilter(false)
  }

  const handleClearFilter = () =>{
    setFilters({
      edad: {
        inicio:"",
        fin: "",
      },
      genero: '',
      estado: '',
      tipoMembresia: '',
      fechaSuscription: {
        inicio:"",
        fin: "",
      },
      fechaCancelacion: {
        inicio:"",
        fin: "",
      },
      fechaReactivacion: {
        inicio:"",
        fin: "",
      },
      fechaVencimiento: {
        inicio:"",
        fin: "",
      },
    });
    HideFiltros()
    setValueSearch('')
    setUsersReportData(usersReportDataFirst)


  }

  const handleGenerateExcel = () =>{


    let key_id= 1;
    // console.log(selectedRowKeys)
    if(selectedRowKeys.length > 0){
      //success()
      const datafilter = usersData.filter((items)=>{
        return selectedRowKeys.includes(items.user.id)
      })
      
     const data = datafilter.map((value)=>{
        return{
          Key:key_id++,
          Nombre: value.user.nombre,
          Apellido: value.user.apellido,
          Edad: calcularEdad(value.user.fechaNacimiento),
          Genero: value.user.sexo,
          "Id Usuario [MoSo ID]": value.user.mosoReference,
          Estatus: value.subscription.status,
          Membresia: value.user.suscription,
          "Fecha de Suscripción":formatoFecha(value.subscription.createdAt),
          "Fecha de Cancelación":value.subscription.fechaCancelacion !==undefined ? formatoFecha(value.subscription.fechaCancelacion):' ',
          "Fecha de Reactivación":value.subscription.fechaReactivacion !==undefined ? formatoFecha(value.subscription.fechaReactivacion) : '',
          "Fecha de Vencimiento":formatoFecha(value.subscription.expiracionPrueba),
          Codigo: value.transaction!==undefined ? value.transaction.codigo:' '         

        }
     })

     downloadExcelFile(data, 'ReporteSuscriptores')
     
    }else{
      messageApi.open({
        type: 'error',
        content: 'No tienes ningún registro selecionado.',
        duration:2,
      });
    }
  }

  return (
    <AdminLayout>
    {contextHolder}
      <Row justify="start">
        <Col span={12} sm={18} md={12} lg={8}>
          <h3 className="mb-8 text-sc-black font-bold text-[24px] text-left md:text-[32px]">
            Reporte Suscriptores
          </h3>
        </Col>
        <Col span={12} sm={6} md={12} lg={16}>
          <Row justify="end">
            <Col className="mr-2">
              <SCButton className="text-sm rounded" onClick={handleGenerateExcel}>Exportar a Excel</SCButton>
            </Col>
            <Col>
              <SCButton className="text-sm rounded" onClick={handleActualizar}>Actualizar</SCButton>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="start">
        <Col xs={12} sm={12} md={8} lg={6}>
          <div className="w-auto ml-2 mr-2">
            <Input
              placeholder="Buscar..."
              allowClear
              value={valueSearch}
              onChange={(e)=>handleSearch(e.target.value)}
              style={{
                borderRadius:"10px"
              }}
              suffix ={<SearchOutlined />}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2 " >
        <Col xs={24} sm={24} md={21} lg={20} >
          <Row>
            <Col xs={12} sm={12} md={8} lg={6}>
            <div className="bg-gray-50  mt-2 ml-2 mr-2 cursor-pointer h-12 sm:h-11 md:h-12 xl:h-10 lg:h-14 w-auto shadow-lg border flex justify-between border-slate-400 rounded-xl" onClick={()=>setShowEdadFilter(!showEdadFilter)}>
              <div className=" ml-4 mt-1 " >
                <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Edad: <b className ="md:text-[12px]">{filters.edad.inicio ? filters.edad.fin ? filters.edad.inicio +" - "+ filters.edad.fin  : filters.edad.inicio + " - "+ " " :  "Seleccionar"}</b> </p>
              </div>
              <div className="mr-4  ">
                <CaretDownOutlined />
              </div>
            </div>
            {showEdadFilter ?
              (
                <div className="  absolute z-20 -mt-4 mb-4 ml-2 mr-2 w-auto border-b border-l border-r border-gray-400  rounded-b-xl bg-gray-50   ">
                  <div className=" flex justify-around border-t mt-2 border-slate-300">
                    <div className="w-1/3 ">
                      <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Desde:</p>
                    </div>
                    <div className="w-1/3 " >
                      <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Hasta:</p>
                    </div>
                  </div>
                  <div>
                  <div className="flex justify-around -mt-4 mb-2 w-full p-2">
                    <div>
                      <InputNumber
                        // onChange={(value)=>handleEdadFilter(value, 0)}
                        onChange={(value)=>handleEdadFilter(value, 0)}
                        type="number"
                        placeholder={"Seleccionar"}
                        size="small"
                        style={{
                          width: "auto",
                          borderRadius:"10px",
                          backgroundColor:"#DADADA ",
                          
                        }}
                        />
                    </div>
                    <div>
                      <MinusOutlined />
                    </div>
                    <div >
                      <InputNumber 
                      onChange={(value)=>handleEdadFilter(value, 1)}
                      type="number"
                      placeholder={"Seleccionar"}
                      size="small"
                      style={{
                        width: "auto",
                        borderRadius:"10px",
                        backgroundColor:"#DADADA ",
                        }}
                      />
                    </div>
                    </div>
                  </div>
                </div>
              )
              :null}
            </Col>
            
          <Col xs={12} sm={12} md={8} lg={6}>
            <div className="bg-gray-50 w-auto  mt-2 ml-2 mr-2 cursor-pointer h-12 sm:h-11 md:h-12 xl:h-10 lg:h-14  shadow-lg border flex flex-row justify-between border-slate-400 rounded-xl" onClick={()=>setShowGeneroFilter(!showGeneroFilter)}>
                <div className="mt-1 ml-4" >
                  <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Género: <b className ="md:text-[12px]">{filters.genero !==""? filters.genero : "Seleccionar"}</b></p>
                </div>
                <div className="mr-4">
                  <CaretDownOutlined />
                </div>
            </div>
              {showGeneroFilter ? 
            (
              <div className=" absolute z-40 -mt-4 mb-4 mr-2 ml-2 w-full     ">
                  <div className=" flex flex-col justify-start mt-1 mr-4 border-b border-l border-r rounded-b-xl border-slate-400 bg-gray-50">
                    <div className="w-full border-t mt-2 -mb-2 border-slate-300" >
                      <ul className="w-full px-2 ">
                        <li onClick={()=>{handleChangeGenero('Masculino'); setShowGeneroFilter(!showGeneroFilter)}} className="text-sm text-[10px] sm:text-[12px] md:text-[14px] text-left w-full hover:bg-slate-200 hover:rounded-md cursor-pointer mr-2 mt-1  pl-1 font-medium">Hombre</li>
                        <li onClick={()=>{handleChangeGenero('Femenino'); setShowGeneroFilter(!showGeneroFilter)}} className="text-sm text-[10px] sm:text-[12px] md:text-[14px] text-left w-full hover:bg-slate-200 hover:rounded-md cursor-pointer mr-2 mt-1  pl-1 font-medium">Mujer</li>
                        <li onClick={()=>{handleChangeGenero('Otro'); setShowGeneroFilter(!showGeneroFilter)}} className="text-sm  text-[10px] sm:text-[12px] md:text-[14px] text-left w-full hover:bg-slate-200 hover:rounded-md cursor-pointer mr-2 mt-1  pl-1 font-medium">Otro</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )
              :null}
            </Col>
           
          <Col xs={12} sm={12} md={8} lg={6}>
            <div className="bg-gray-50  mt-2  ml-2 mr-2  cursor-pointer h-12 sm:h-11 md:h-12 xl:h-10 lg:h-14 w-auto shadow-lg border flex flex-row justify-between border-slate-400 rounded-xl" onClick={()=>setShowEstatusFilter(!showEstatusFilter)}>
                <div className=" ml-4 mt-1 " >
                  <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Estatus: <b className ="md:text-[12px]">{filters.estado !== "" ? filters.estado : "Seleccionar"}</b></p>
                </div>
                <div className=" mr-4  ">
                  <CaretDownOutlined />
                </div>
            </div>
            {showEstatusFilter? 
              (
                <div className=" absolute z-40 -mt-4 mb-4 mr-2 ml-2 w-full     ">
                  <div className=" flex flex-col justify-start mt-1 mr-4 border-b border-l border-r rounded-b-xl border-slate-400 bg-gray-50">
                    <div className="w-full border-t mt-2 -mb-2 border-slate-300" >
                      <ul className="w-full px-2 ">
                        <li onClick={()=>{handleChangeStatus("Active"); setShowEstatusFilter(!showEstatusFilter);}} className="text-sm text-[10px] sm:text-[12px] md:text-[14px] text-left w-full hover:bg-slate-200 hover:rounded-md cursor-pointer mr-2 mt-1  pl-1 font-medium">Activo</li>
                        <li onClick={()=>{handleChangeStatus("trial"); setShowEstatusFilter(!showEstatusFilter);}} className="text-sm  text-[10px] sm:text-[12px] md:text-[14px] text-left w-full hover:bg-slate-200 hover:rounded-md cursor-pointer mr-2 mt-1  pl-1 font-medium">Inactivo</li>
                        <li onClick={()=>{handleChangeStatus("Cancelled"); setShowEstatusFilter(!showEstatusFilter);}} className="text-sm text-[10px] sm:text-[12px] md:text-[14px] text-left w-full hover:bg-slate-200 hover:rounded-md cursor-pointer mr-2 mt-1  pl-1 font-medium">Cancelado</li>
                      </ul>
                    </div>
                  </div>
                </div>
                

              ):null}
              </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
            <div className="bg-gray-50  mt-2  ml-2 mr-2 cursor-pointer h-12 sm:h-11 md:h-12 xl:h-10 lg:h-14 w-auto shadow-lg border flex justify-between border-slate-400 rounded-xl" onClick={()=>setShowMembresiaFilter(!showMembresiaFilter)}>
              <div className=" ml-2 mt-1 " >
                <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Tipo Membresia: <b className ="md:text-[12px]">{filters.tipoMembresia !=="" ? filters.tipoMembresia: 'Seleccionar'}</b></p>
              </div>
              <div className="mr-4  ">
                <CaretDownOutlined />
              </div>
            </div>
            {showMembresiaFilter ?
              (
                <div className=" absolute z-40 -mt-4 mb-4 mr-2 ml-2 w-full     ">
                  <div className=" flex flex-col justify-start mt-1 mr-4 border-b border-l border-r rounded-b-xl border-slate-400 bg-gray-50">
                    <div className="w-full border-t mt-2 -mb-2 border-slate-300" >
                      <ul className="w-full px-2 ">
                        <li onClick={()=>{handleChangeMembresia("Premium"); setShowMembresiaFilter(!showMembresiaFilter)}} className="text-sm text-[10px] sm:text-[12px] md:text-[14px] text-left w-full hover:bg-slate-200 hover:rounded-md cursor-pointer mr-2 mt-1  pl-1 font-medium">Premium</li>
                        <li onClick={()=>{handleChangeMembresia("Free"); setShowMembresiaFilter(!showMembresiaFilter)}} className="text-sm text-[10px] sm:text-[12px] md:text-[14px] text-left w-full hover:bg-slate-200 hover:rounded-md cursor-pointer mr-2 mt-1  pl-1 font-medium">Free</li>
                        <li onClick={()=>{handleChangeMembresia("B2B"); setShowMembresiaFilter(!showMembresiaFilter)}} className="text-sm text-[10px] sm:text-[12px] md:text-[14px] text-left w-full hover:bg-slate-200 hover:rounded-md cursor-pointer mr-2 mt-1  pl-1 font-medium">b2b</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )
              :null}
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
            <div className="bg-gray-50  mt-2 mr-2 ml-2 cursor-pointer  h-auto  w-auto shadow-lg border flex justify-between border-slate-400 rounded-xl" onClick={()=>setShowSuscripcionFilter(!showSuscripcionFilter)}>
              <div className=" ml-4 mt-1" >
                <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Fecha de suscripción: </p>
                <p className="text-xs text-left -mt-3 mb-1 text-[10px] sm:text-[12px] md:text-[12px]"><b className ={ showSuscripcionFilter ? 'opacity-0' : '' }>{filters.fechaSuscription.inicio === "" && filters.fechaSuscription.fin==="" ? "Selecionar": `${formatoFecha(moment(filters.fechaSuscription.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechaSuscription.fin)).replaceAll('-','/')}`}</b></p>
              </div>
              <div className="mr-4 ">
                <CaretDownOutlined />
              </div>
            </div>
            {showSuscripcionFilter ?
              (
                <div className=" absolute z-10 -mt-4 mb-4  md:-mt-2 ml-2 mr-2  w-full h-auto">
                  <div className=" mr-4  border-b border-l border-r -mt-2 border-gray-400  rounded-b-xl  bg-gray-50  ">
                  <p className="text-xs text-left mt-1 ml-4 mb-0 md:-mt-4 lg:-mt-4 text-[10px] sm:text-[12px] md:text-[12px] ">
                  <b className ="md:text-[12px]">{filters.fechaSuscription.inicio === "" && filters.fechaSuscription.fin==="" ?
                      "Selecionar":
                      `${formatoFecha(moment(filters.fechaSuscription.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechaSuscription.fin)).replaceAll('-','/')}`}</b>
                  </p>
                      <div className="flex justify-around border-t border-slate-300 mt-0 pt-2 ">
                        <div className="w-1/3 ">
                          <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Desde:</p>
                        </div>
                        <div className="w-1/3 " >
                          <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Hasta:</p>
                        </div>
                    </div>
                  <div>
                    <div className="flex justify-around p-2 -mt-4 mb-2">
                      <div>
                        <DatePicker 
                          onChange={(value)=>handleFechaSuscription(value, true)}
                          suffixIcon={""}
                          placeholder={"Seleccionar"}
                          format={"DD/MM/YYYY"}
                          style={{
                            borderRadius:"10px",
                            backgroundColor:"#DADADA ",
                            
                          }}
                          />
                      </div>
                      <div>
                        <MinusOutlined />
                      </div>
                      <div >
                        <DatePicker 
                        onChange={(value)=>handleFechaSuscription(value, false)}
                        suffixIcon={""}
                        placeholder={"Seleccionar"}
                        format={"DD/MM/YYYY"}
                        style={{
                          borderRadius:"10px",
                          backgroundColor:"#DADADA ",
                          }}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
              :null}
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <div className="bg-gray-50  mt-2 ml-2 mr-2 cursor-pointer h-auto w-auto shadow-lg border flex justify-between border-slate-400 rounded-xl" onClick={()=>setShowCancelacionFilter(!showCancelacionFilter)}>
              <div className=" ml-4 mt-1 " >
                <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Fecha de cancelación: </p>
                <p className="text-xs text-left -mt-3 mb-1 text-[10px] sm:text-[12px] md:text-[12px]">
                  <b className ={ showCancelacionFilter ? 'opacity-0' : '' }>{filters.fechaCancelacion.inicio === "" && filters.fechaCancelacion.fin==="" ? 
                      "Selecionar": `
                      ${formatoFecha(moment(filters.fechaCancelacion.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechaCancelacion.fin)).replaceAll('-','/')}`}
                  </b>
                </p>
              </div>
              <div className="mr-4  ">
                <CaretDownOutlined />
              </div>
            </div>
            {showCancelacionFilter ?
              (
                <div className="absolute z-20 -mt-4 mb-4 ml-2 md:-mt-2 md:pt-1 mr-2 w-full">
                  <div className=" mr-4 border-b border-l border-r -mt-2 border-gray-400  rounded-b-xl bg-gray-50   ">
                  <p className="text-xs text-left mt-1 ml-4 mb-0 md:-mt-4 lg:-mt-4 text-[10px] sm:text-[12px] md:text-[12px] ">
                  <b className ="md:text-[12px]">{filters.fechaCancelacion.inicio === "" && filters.fechaCancelacion.fin==="" ?
                      "Selecionar":
                      `${formatoFecha(moment(filters.fechaCancelacion.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechaCancelacion.fin)).replaceAll('-','/')}`}</b>
                  </p>
                    <div className=" flex justify-around border-t mt-0 pt-2 border-slate-300">
                      <div className="w-1/3 ">
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Desde:</p>
                      </div>
                      <div className="w-1/3 " >
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Hasta:</p>
                      </div>
                    </div>
                    <div>
                    <div className="flex justify-around p-2 -mt-4 mb-2">
                      <div>
                        <DatePicker 
                          onChange={(value)=>handleDateCancel(value, true)}
                          suffixIcon={""}
                          placeholder={"Seleccionar"}
                          format={"DD/MM/YYYY"}
                          style={{
                            borderRadius:"10px",
                            backgroundColor:"#DADADA ",
                            
                          }}
                          />
                      </div>
                      <div>
                        <MinusOutlined />
                      </div>
                      <div >
                        <DatePicker 
                        onChange={(value)=>handleDateCancel(value, false)}
                        suffixIcon={""}
                        placeholder={"Seleccionar"}
                        format={"DD/MM/YYYY"}
                        style={{
                          borderRadius:"10px",
                          backgroundColor:"#DADADA ",
                          }}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
              :null}
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <div className="bg-gray-50  mt-2 ml-2 mr-2 cursor-pointer h-auto w-auto shadow-lg border flex justify-between border-slate-400 rounded-xl" onClick={()=>setShowReactivacionFilter(!showReactivacionFilter)}>
              <div className=" ml-4 mt-1 " >
                <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Fecha de reactivacion: </p>
                <p className="text-xs text-left -mt-3 mb-1 text-[10px] sm:text-[12px] md:text-[12px]">
                  <b className ={ showReactivacionFilter ? 'opacity-0' : '' }>{filters.fechaReactivacion.inicio === "" && filters.fechaReactivacion.fin==="" ? 
                      "Selecionar": 
                      `${formatoFecha(moment(filters.fechaReactivacion.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechaReactivacion.fin)).replaceAll('-','/')}`}</b>
                </p>
              </div>
              <div className="mr-4  ">
                <CaretDownOutlined />
              </div>
            </div>
            {showReactivacionFilter ?
              (
                <div className="absolute z-20 -mt-4 mb-4 ml-2 md:-mt-2 md:pt-1 mr-2 w-full">
                  <div className=" mr-4 -mt-2 border-b border-l border-r border-gray-400  rounded-b-xl bg-gray-50   ">
                  <p className="text-xs text-left mt-1 ml-4 mb-0 md:-mt-4 lg:-mt-4 text-[10px] sm:text-[12px] md:text-[12px] ">
                  <b className ="md:text-[12px]">{filters.fechaReactivacion.inicio === "" && filters.fechaReactivacion.fin==="" ?
                      "Selecionar":
                      `${formatoFecha(moment(filters.fechaReactivacion.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechaReactivacion.fin)).replaceAll('-','/')}`}</b>
                  </p>
                    <div className=" flex justify-around border-t mt-0 pt-2 border-slate-300">
                      <div className="w-1/3 ">
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Desde:</p>
                      </div>
                      <div className="w-1/3 " >
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Hasta:</p>
                      </div>
                    </div>
                    <div>
                    <div className="flex justify-around p-2 -mt-4 mb-2">
                      <div>
                        <DatePicker 
                          onChange={(value)=>{handleDateReactive(value, true)}}
                          suffixIcon={""}
                          placeholder={"Seleccionar"}
                          format={"DD/MM/YYYY"}
                          style={{
                            borderRadius:"10px",
                            backgroundColor:"#DADADA ",
                            
                          }}
                          />
                      </div>
                      <div>
                        <MinusOutlined />
                      </div>
                      <div >
                        <DatePicker 
                        onChange={(value)=>{handleDateReactive(value, false)}}
                        suffixIcon={""}
                        placeholder={"Seleccionar"}
                        format={"DD/MM/YYYY"}
                        style={{
                          borderRadius:"10px",
                          backgroundColor:"#DADADA ",
                          }}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              )
              :null}
          </Col>
          
            
          <Col xs={12} sm={12} md={8} lg={6}>
            <div className="bg-gray-50  mt-2 ml-2 mr-2 cursor-pointer h-auto w-auto shadow-lg border flex justify-between border-slate-400 rounded-xl" onClick={()=>setShowVencimientoFilter(!showVencimientoFilter)}>
              <div className=" ml-4 mt-1 " >
                <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">Fecha de vencimiento: </p>
                <p className="text-xs text-left -mt-3 mb-1 text-[10px] sm:text-[12px] md:text-[12px]">
                  <b className ={ showVencimientoFilter ? 'opacity-0' : '' }>
                  {filters.fechaVencimiento.inicio === "" && filters.fechaVencimiento.fin==="" ? 
                        "Selecionar": 
                        `${formatoFecha(moment(filters.fechaVencimiento.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechaVencimiento.fin)).replaceAll('-','/')}`}
                  </b>
                </p>
              </div>
              <div className="mr-4  ">
                <CaretDownOutlined />
              </div>
            </div>
            {showVencimientoFilter ?
              (
                <div className="absolute z-20 -mt-4 mb-4 ml-2 md:-mt-2 md:pt-1 mr-2 w-full">
                  <div className=" mr-4 -mt-2 border-b border-l border-r border-gray-400  rounded-b-xl bg-gray-50   ">
                  <p className="text-xs text-left mt-1 ml-4 mb-0 md:-mt-4 lg:-mt-4 text-[10px] sm:text-[12px] md:text-[12px] ">
                  <b className ="md:text-[12px]">{filters.fechaVencimiento.inicio === "" && filters.fechaVencimiento.fin==="" ?
                      "Selecionar":
                      `${formatoFecha(moment(filters.fechaVencimiento.inicio)).replaceAll('-','/')} - ${formatoFecha(moment(filters.fechaVencimiento.fin)).replaceAll('-','/')}`}</b>
                  </p>
                    <div className=" flex justify-around border-t mt-0 pt-2 border-slate-300">
                      <div className="w-1/3 ">
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Desde:</p>
                      </div>
                      <div className="w-1/3 " >
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Hasta:</p>
                      </div>
                    </div>
                    <div>
                    <div className="flex justify-around p-2 -mt-4 mb-2">
                      <div>
                        <DatePicker 
                          onChange={(value)=>{handleDateVencimiento(value, true)}}
                          suffixIcon={""}
                          placeholder={"Seleccionar"}
                          format={"DD/MM/YYYY"}
                          style={{
                            borderRadius:"10px",
                            backgroundColor:"#DADADA ",
                            
                          }}
                          />
                      </div>
                      <div>
                        <MinusOutlined />
                      </div>
                      <div >
                        <DatePicker 
                        onChange={(value)=>{handleDateVencimiento(value, false)}}
                        suffixIcon={""}
                        placeholder={"Seleccionar"}
                        format={"DD/MM/YYYY"}
                        style={{
                          borderRadius:"10px",
                          backgroundColor:"#DADADA ",
                          }}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              )
              :null}
          </Col>
          </Row>
        </Col>
        
        <Col sm={24} md={3} lg={4}>
          <div className="flex  md:flex-col content-center">
            <div className="bg-gray-50  border shadow-lg border-slate-300 h-10 md:h-11 cursor-pointer ml-2 mt-2 w-auto rounded-xl flex  justify-around" onClick={()=>{handleDataFilter()}}>
              <div className="mt-2 ml-2  md:ml-5 lg:ml-0 ">
                <IoFilterSharp size={18}/>
              </div>
              <div className="mt-2 ml-2 mr-8 text-sm text-[10px] sm:text-[12px] md:text-[14px]">
                <p>Filtrar</p>
              </div>
            </div>

            <div className="bg-gray-50  border shadow-lg border-slate-300 h-10 md:11 cursor-pointer ml-2 mt-2 w-auto rounded-xl flex justify-around" onClick={()=>{handleClearFilter()}}>
              <div className="mt-2 ml-2 md:ml-5 lg:ml-2 ">
                <IoFilterSharp size={18}/>
              </div>
              <div className="mt-2 ml-2 mr-8 text-xs text-[10px] sm:text-[12px] md:text-[14px]">
                <p>Reset Filtro</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      
      <Table
        className="mt-4 cursor-pointer"
        rowSelection={rowSelection}
        dataSource={dataSource}
        columns={columns}
        size="small"
        rowKey = {(record)=> record.key}
        loading={loading}
        scroll ={{
          x:1300,
        }}
        pagination={{showSizeChanger: true}}
      />
    </AdminLayout>
  );
};

export default ReporteSuscriptores;
