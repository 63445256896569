import React, { useEffect, useState } from "react";
import { serverUri } from "../../api/serverUri";
import { AutoComplete } from "antd";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Space,
  Checkbox,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  createNotification,
  searchContents,
  updateNotification,
} from "../../api/notifications";
import moment from "moment";

export const FormNotification = ({
  onCancel,
  idFirebase,
  showDate,
  setShowDate,
}) => {
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [valueLink, setValueLink] = useState("");
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();

  const formatCustomDate = (date) => {
    const dateString = date.format("YYYY-MM-DD HH:mm:ss");
    const [datePart, timePart] = dateString.split(" ");
    const formattedDate = `${datePart}T${timePart.slice(0, 5)}:00.000Z`;
    return formattedDate;
  };

  const onFinish = async (fields) => {
    const authToken = localStorage.getItem("token");
    const { datepicker, description, route, title, type, targetDevice } =
      fields;
    setLoadingForm(true);

    const body = {
      title,
      type,
      content: description ?? "",
      scheduledTime: datepicker ? formatCustomDate(datepicker) : "",
      route: route ?? "",
      targetDevice: {
        ios: targetDevice.includes("ios"),
        android: targetDevice.includes("android"),
        pc: false,
      },
    };

    setTimeout(async () => {
      if (idFirebase && idFirebase !== 0) {
        const resp = await updateNotification(idFirebase, body, authToken);
        if (resp) {
          message.success("Notificación actualizada con éxito");
        } else {
          message.error("Error al actualizar la notificación");
        }
        setLoadingForm(false);
      } else {
        const resp = await createNotification(body, authToken);
        if (resp) {
          message.success("Notificación programada con éxito");
          form.resetFields();
        } else {
          message.error("Error al programar la notificación");
        }
        setLoadingForm(false);
      }
    }, 5000);
  };
  const onFinishFailed = () => {};

  const onSelect = (data) => {
    setValueLink(data);
  };

  const onChangeProgrammed = (value) => {
    setShowDate(value === "Sí");
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues.type) {
      // Borra el valor de route solo si ya había un valor en route
        form.setFieldsValue({ route: "" });
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        form.resetFields();

        if (!idFirebase || idFirebase === 0) {
          setLoading(false);
          return;
        }

        const authToken = localStorage.getItem("token");
        const response = await fetch(
          `${serverUri}/notification/${idFirebase}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const docData = await response.json();

        if (docData) {
          form.setFieldsValue({
            title: docData.title,
            route: docData.route,
            description: docData.content,
            programmed: docData.scheduledTime ? "Sí" : "No",
            type: docData.type,
            datepicker: docData.scheduledTime
              ? moment(docData.scheduledTime)
              : null,
            targetDevice: [
              docData.targetDevice?.ios ? "ios" : null,
              docData.targetDevice?.android ? "android" : null,
              docData.targetDevice?.pc ? "pc" : null,
            ].filter(Boolean),
          });
          setShowDate(!!docData.scheduledTime);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }

      setLoading(false);
    };

    getData();
  }, [idFirebase]);

  const searchContentsAsync = async (text) => {
    const authToken = localStorage.getItem("token");

    const { type } = form.getFieldsValue();

    const body = {
      keyword: text,
      isPremium: type === "Premium" ? true : false,
    };

    await searchContents(body, authToken).then((data) => {
      if (data && data.length > 0) {
        setOptions([]);
        setValueLink(text);
        setOptions(
          data.map((item) => ({
            // value: `https://salmon-ocean-018b64610.1.azurestaticapps.net/${item.id}`,
            value: `${item.id}`,
            label: item.titulo,
          }))
        );
      }
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      onValuesChange={handleValuesChange}
    >
      <div className="columns-3">
        <Form.Item
          name="title"
          label="Título"
          rules={[
            {
              required: true,
              message: "El título es obligatorio",
            },
          ]}
        >
          <Input
            style={{
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
              boxShadow: "none",
            }}
          />
        </Form.Item>
        <Form.Item
          label="Selecciona el tipo de usuario"
          name="type"
          rules={[
            {
              required: true,
              message: "Este campo es obligatorio",
            },
          ]}
        >
          <Select
            style={{
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
              boxShadow: "none",
              borderRadius: "10px",
            }}
          >
            <Select.Option value="Free">Free</Select.Option>
            <Select.Option value="Premium">Premium</Select.Option>
            <Select.Option value="Ambos">Ambos</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="route"
          label="Link/Enlace"
          rules={[
            {
              type: "text",
              message: "Por favor, introduce un enlace válido",
            },
          ]}
        >
          <AutoComplete
            options={options}
            style={{
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
              boxShadow: "none",
            }}
            onSelect={onSelect}
            onSearch={searchContentsAsync}
            placeholder="input here"
          />
        </Form.Item>
      </div>
      <div className="columns-1">
        <Form.Item
          name="description"
          label="Descripción"
          rules={[
            {
              required: true,
            },
            {
              type: "text",
              warningOnly: true,
            },
            {
              type: "string",
            },
          ]}
        >
          <TextArea
            rows={4}
            placeholder="Descripción del evento"
            style={{
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
              boxShadow: "none",
              backgroundColor: "#f9f9f9",
            }}
          />
        </Form.Item>
      </div>
      <div className="columns-2">
        <Form.Item
          label="¿Notificación Programada?"
          name="programmed"
          rules={[
            {
              required: true,
              message: "Este campo es obligatorio",
            },
          ]}
        >
          <Select
            onChange={onChangeProgrammed}
            style={{
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
              boxShadow: "none",
              borderRadius: "10px",
            }}
          >
            <Select.Option value="Sí">Sí</Select.Option>
            <Select.Option value="No">No</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="targetDevice"
          label="Seleccionar Dispositivos"
          rules={[
            {
              required: true,
              message: "Por favor selecciona al menos un dispositivo",
            },
          ]}
        >
          <Checkbox.Group>
            <Checkbox value="ios">iOS</Checkbox>
            <Checkbox value="android">Android</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </div>
      {showDate && (
        <div className="columns-1">
          <Form.Item
            name="datepicker"
            label="Fecha y hora programada"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona una fecha y hora",
              },
            ]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              style={{
                width: "49%",
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                boxShadow: "none",
              }}
              onChange={(value) => {
                if (value) {
                  form.setFieldsValue({
                    datepicker: value,
                  });
                }
              }}
            />
          </Form.Item>
        </div>
      )}

      <Form.Item>
        <Space
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "5%",
          }}
        >
          <Button
            htmlType="button"
            style={{ border: "0", fontSize: "16px", marginRight: "50px" }}
            onClick={onCancel}
          >
            Cancelar
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            disabled={loadingForm}
            style={{
              border: "0",
              borderRadius: "20px",
              padding: "10px 30px",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              color: "#000",
              width: "170px",
            }}
          >
            {loadingForm
              ? "Enviando..."
              : idFirebase && idFirebase !== 0
              ? "Actualizar"
              : "Enviar"}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
