import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  DatePicker,
  TimePicker,
  message,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { createAlert, updateAlert, uploadImage } from "../../api/alertas";
import "../../styles/formAlert.css";

const { Option } = Select;

const FormAlert = ({ onCancel, alert, onOk }) => {
  const [form] = Form.useForm();
  const [isScheduled, setIsScheduled] = useState(
    alert ? alert.isScheduled : false
  );
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (alert) {
      form.setFieldsValue({
        ...alert,
        startDate: alert.startDate ? moment(alert.startDate) : null,
        endDate: alert.endDate ? moment(alert.endDate) : null,
        scheduledTime: alert.scheduledTime
          ? moment(alert.scheduledTime, "HH:mm:ss")
          : null,
        endScheduledTime: alert.endScheduledTime
          ? moment(alert.endScheduledTime, "HH:mm:ss")
          : null,
        days: alert.days
          ? Object.keys(alert.days).filter((key) => alert.days[key])
          : [],
        targetDevice: alert.targetDevice
          ? Object.keys(alert.targetDevice).filter(
              (key) => alert.targetDevice[key]
            )
          : [],
        isScheduled: alert.isScheduled ? "true" : "false",
      });

      if (alert.image) {
        setFileList([
          {
            uid: alert.image.id,
            name: "imagen",
            status: "done",
            url: `${alert.image.url}`,
          },
        ]);
      }
    } else {
      form.resetFields();
      setFileList([]);
      form.setFieldsValue({
        days: [],
        targetDevice: [],
        isScheduled: "false",
      });
    }
  }, [alert, form]);

  const validateFields = (_, value) => {
    if (!value || !value.trim()) {
      return Promise.reject("El campo no puede estar vacío");
    }
    return Promise.resolve();
  };

  const validateDates = (_, value) => {
    const startDate = form.getFieldValue("startDate");
    if (startDate && value && value.isBefore(startDate)) {
      return Promise.reject(
        "La fecha de fin no puede ser menor a la fecha de inicio"
      );
    }
    return Promise.resolve();
  };

  const onCancelButton = async () => {
    form.resetFields();
    setFileList([]);
    form.setFieldsValue({
      days: [],
      targetDevice: [],
      isScheduled: "false",
    });
    onCancel();
  };

  const onFinish = async (values) => {
    const authToken = localStorage.getItem("token");

    const targetDevice = {
      ios: values.targetDevice.includes("ios"),
      android: values.targetDevice.includes("android"),
      pc: values.targetDevice.includes("pc"),
    };

    const days = {
      L: values.days?.includes("L") || false,
      M: values.days?.includes("M") || false,
      MM: values.days?.includes("MM") || false,
      J: values.days?.includes("J") || false,
      V: values.days?.includes("V") || false,
      S: values.days?.includes("S") || false,
      D: values.days?.includes("D") || false,
    };

    const payload = {
      ...values,
      startDate: values.startDate ? values.startDate.toISOString() : null,
      endDate: values.endDate ? values.endDate.toISOString() : null,
      scheduledTime: values.scheduledTime
        ? values.scheduledTime.format("HH:mm:ss")
        : null,
      endScheduledTime: values.endScheduledTime
        ? values.endScheduledTime.format("HH:mm:ss")
        : null,
      days,
      targetDevice,
      image: fileList[0].uid,
      isScheduled: values.isScheduled === "true",
    };

    try {
      if (alert) {
        await updateAlert(alert.id, payload, authToken);
        message.success("Alerta actualizada con éxito");
        form.resetFields();
        setFileList([]);
        form.setFieldsValue({
          days: [],
          targetDevice: [],
          isScheduled: "false",
        });
      } else {
        await createAlert(payload, authToken);
        message.success("Alerta creada con éxito");
      }
      form.resetFields();
      setFileList([]);
      form.setFieldsValue({
        days: [],
        targetDevice: [],
        isScheduled: "false",
      });
      onOk();
    } catch (error) {
      console.error("Error al guardar la alerta:", error);
      message.error("Ocurrió un error al guardar la alerta");
    }
  };

  const handleUpload = async ({ file, onSuccess, onError }) => {
    const authToken = localStorage.getItem("token");
    setLoading(true);
    try {
      const uploadedImage = await uploadImage(file, authToken);
      setFileList([
        { uid: uploadedImage.id, url: uploadedImage.url, name: file.name },
      ]);
      form.setFieldsValue({ image: uploadedImage.id });
      onSuccess(null, file);
      message.success("Imagen subida con éxito");
    } catch (error) {
      onError(error);
      message.error("Error al subir la imagen");
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <div style={{ display: "flex", gap: "16px" }}>
        <Form.Item
          name="title"
          label={<span className="custom-label">Título</span>}
          style={{ flex: 1 }}
          rules={[
            { required: true, message: "Por favor ingresa un título" },
            { validator: validateFields },
          ]}
        >
          <Input className="custom-input" />
        </Form.Item>
        <Form.Item
          name="link"
          label={<span className="custom-label">Link</span>}
          style={{ flex: 1 }}
        >
          <Input className="custom-input" />
        </Form.Item>
        <Form.Item
          name="image"
          label={<span className="custom-label">Imagen</span>}
          style={{ flex: 1 }}
          rules={[{ required: true, message: "Por favor sube una imagen" }]}
        >
          <Upload
            accept=".jpg,.jpeg,.png,.gif"
            customRequest={handleUpload}
            fileList={fileList}
            showUploadList={{ showRemoveIcon: false }}
          >
            <Button
              icon={<UploadOutlined />}
              loading={loading}
              className="ant-button"
            >
              Subir Imagen
            </Button>
          </Upload>
          {fileList.length > 0 && (
            <a
              href={`${fileList[0].uid}`}
              onClick={(e) => {
                e.preventDefault();
                handleImageClick(`${fileList[0].uid}`);
              }}
            ></a>
          )}
        </Form.Item>
      </div>

      <Form.Item>
        <h3>Periodicidad</h3>
      </Form.Item>

      <div style={{ display: "flex", gap: "16px" }}>
        <Form.Item
          style={{ flex: 1 }}
          name="isScheduled"
          rules={[
            { required: true, message: "Por favor selecciona una opción" },
          ]}
        >
          <Select
            className="custom-select"
            value={isScheduled ? "true" : "false"}
            onChange={(value) => setIsScheduled(value === "true")}
            placeholder="Notificación Programada"
          >
            <Option value="true">Programada</Option>
            <Option value="false">No Programada</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="targetUserType"
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              message: "Por favor selecciona al tipo de usuario",
            },
          ]}
        >
          <Select
            className="custom-select"
            placeholder="Seleccionar al tipo de usuario"
          >
            <Option value="Free">Free</Option>
            <Option value="Premium">Premium</Option>
            <Option value="Ambos">Ambos</Option>
          </Select>
        </Form.Item>
      </div>
      {isScheduled && (
        <>
          <div style={{ display: "flex", gap: "16px" }}>
            <Form.Item
              name="days"
              label={<span className="custom-label">Selecciona los días</span>}
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  message: "Por favor selecciona al menos un día",
                },
              ]}
            >
              <Checkbox.Group options={["L", "M", "MM", "J", "V", "S", "D"]} />
            </Form.Item>
            <div style={{ display: "flex", gap: "16px", flex: 1 }}>
              <Form.Item
                name="scheduledTime"
                label={<span className="custom-label">Hora Inicio</span>}
                style={{ flex: 1 }}
                rules={[
                  { required: true, message: "Por favor selecciona una hora" },
                ]}
              >
                <TimePicker
                  className="custom-time-picker"
                  format="HH:mm:ss"
                  placeholder="Seleccionar Hora"
                />
              </Form.Item>
              <Form.Item
                name="endScheduledTime"
                label={<span className="custom-label">Hora Fin</span>}
                style={{ flex: 1 }}
                rules={[
                  { required: true, message: "Por favor selecciona una hora" },
                ]}
              >
                <TimePicker
                  className="custom-time-picker"
                  format="HH:mm:ss"
                  placeholder="Seleccionar Hora"
                />
              </Form.Item>
            </div>
          </div>
          <div style={{ display: "flex", gap: "16px" }}>
            <Form.Item
              name="startDate"
              label={<span className="custom-label">Fecha Inicio</span>}
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  message: "Por favor selecciona una fecha de inicio",
                },
              ]}
            >
              <DatePicker
                className="custom-date-picker"
                format="DD/MM/YYYY"
                placeholder="Seleccionar Fecha"
              />
            </Form.Item>
            <Form.Item
              name="endDate"
              label={<span className="custom-label">Fecha Fin</span>}
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  message: "Por favor selecciona una fecha de fin",
                },
                { validator: validateDates },
              ]}
            >
              <DatePicker
                className="custom-date-picker"
                format="DD/MM/YYYY"
                placeholder="Seleccionar Fecha"
              />
            </Form.Item>
          </div>
        </>
      )}

      <Form.Item
        name="targetDevice"
        label={<span className="custom-label">Seleccionar Dispositivos</span>}
        rules={[
          {
            required: true,
            message: "Por favor selecciona al menos un dispositivo",
          },
        ]}
      >
        <Checkbox.Group>
          <Checkbox value="android">Android</Checkbox>
          <Checkbox value="pc">PC</Checkbox>
          <Checkbox value="ios">iOS</Checkbox>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Enviar
        </Button>
        <Button onClick={onCancelButton} style={{ marginLeft: "10px" }}>
          Cancelar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormAlert;
