import React, { useState, useEffect } from "react";
import { Table, Input, Button, message, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import AdminLayout from "../components/AdminLayout"; // Asumiendo la ruta correcta de tu layout
import { serverUri } from "../api/serverUri";

const ReporteCalificaciones = () => {
  const [dataClases, setDataClases] = useState([]);
  const [dataCoaches, setDataCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTextClase, setSearchTextClase] = useState("");
  const [searchTextCoach, setSearchTextCoach] = useState("");

  useEffect(() => {
    cargarDatos();
  }, []);

  const getCoachIdFromRecord = (record) => {
    return record.audio
      ? record.audio.coach
      : record.card
      ? record.coachId
      : record.entrenamiento
      ? record.entrenamiento.coach
      : null;
  };

  const cargarDatos = async () => {
    setLoading(true);
    try {
      const responseClases = await axios.get(`${serverUri}/promedio-clases`);
      const responseCoaches = await axios.get(`${serverUri}/promedio-coaches`);
      setDataClases(responseClases.data || []);
      setDataCoaches(responseCoaches.data || []);
    } catch (error) {
      message.error("Error al cargar los datos");
      console.error(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Nombre de la clase",
      dataIndex: "nombreClase",
      key: "nombreClase",
      render: (_, record) =>
        record.card
          ? record.card.titulo
          : record.audio
          ? record.audio.nombre
          : record.entrenamiento
          ? record.entrenamiento.nombre
          : "N/A",
    },
    {
      title: "Calificación de la clase",
      dataIndex: "promedio",
      key: "calificacionClase",
      render: (text) => (
        <span>
          {text} / 5{" "}
          <span
            style={{ fontSize: "18px", position: "relative", top: "0.5px" }}
          >
            ★
          </span>
        </span>
      ),
    },
    {
      title: "Nombre del coach",
      key: "nombreCoach",
      render: (_, record) => {
        const coachId = getCoachIdFromRecord(record);
        const coachData = dataCoaches.find(
          (coach) => coach.coach && coach.coach._id === coachId
        );
        if (coachData) {
          return coachData.coach.nombre;
        }
        return "N/A";
      },
    },
    {
      title: "Calificación del coach",
      key: "calificacionCoach",
      render: (_, record) => {
        const coachId = getCoachIdFromRecord(record);
        const coachData = dataCoaches.find(
          (coach) => coach.coach && coach.coach._id === coachId
        );
        return coachData ? (
          <div>
            {`${coachData.promedio} / 5`}{" "}
            <span
              style={{ fontSize: "18px", position: "relative", top: "0.5px" }}
            >
              ★
            </span>
          </div>
        ) : (
          "N/A"
        );
      },
    },
  ];

  const handleSearchClase = (e) => {
    setSearchTextClase(e.target.value);
  };

  const handleSearchCoach = (e) => {
    setSearchTextCoach(e.target.value);
  };

  const handleClear = () => {
    setSearchTextClase("");
    setSearchTextCoach("");
    cargarDatos();
  };

  const filteredData = dataClases.filter((clase) => {
    const claseTitle = clase.card
      ? clase.card.titulo
      : clase.audio
      ? clase.audio.nombre
      : clase.entrenamiento
      ? clase.entrenamiento.nombre
      : "";
    const claseMatches = searchTextClase
      ? claseTitle.toLowerCase().includes(searchTextClase.toLowerCase())
      : true;

    // Ajustar para obtener correctamente el coachId basándose en el tipo
    let coachId = clase.audio
      ? clase.audio.coach
      : clase.card
      ? clase.coachId
      : clase.entrenamiento
      ? clase.entrenamiento.coach
      : null;
    const coachData = dataCoaches.find((coach) => {
      const match = coach.coach && coach.coach._id === coachId;
      return match;
    });

    const coachName = coachData ? coachData.coach.nombre : "";
    const coachMatches = searchTextCoach
      ? coachName.toLowerCase().includes(searchTextCoach.toLowerCase())
      : true;

    return claseMatches && coachMatches;
  });

  return (
    <AdminLayout>
      <Row justify="start" style={{ marginBottom: 16 }}>
        <Col span={12} sm={18} md={12} lg={8}>
          <h3 className="mb-8 text-sc-black font-bold text-[24px] text-left md:text-[32px]">
            Reporte de Calificaciones
          </h3>
        </Col>
      </Row>

      <Row style={{ marginBottom: 16 }}>
        <Input
          placeholder="Buscar por clase..."
          value={searchTextClase}
          onChange={handleSearchClase}
          style={{ width: 200, marginRight: 8, borderRadius: "10px" }}
          prefix={<SearchOutlined />}
        />
        <Input
          placeholder="Buscar por coach..."
          value={searchTextCoach}
          onChange={handleSearchCoach}
          style={{ width: 200, marginRight: 8, borderRadius: "10px" }}
          prefix={<SearchOutlined />}
        />
        <Button onClick={handleClear} style={{ borderRadius: "10px" }}>
          Reset Filtro
        </Button>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        loading={loading}
        rowKey={(record) => record.id || record._id}
      />
    </AdminLayout>
  );
};

export default ReporteCalificaciones;
