import axios from "axios";
import { serverUri } from "./serverUri";

// Obtener todos los banners del carrousel con filtrado y ordenamiento
export const getCarrouselBanners = async (token, sort = "createdAt:asc") => {
  const response = await axios.get(`${serverUri}/banners-carrousel?_sort=${sort}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getSections = async (token) => {
    const response = await axios.get(`${serverUri}/seccions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  export const searchContents = (data, authToken) => {
    return fetch(`${serverUri}/cards/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error al crear la pregunta:", error);
        message.error("Ocurrió un error, inténtalo de nuevo");
      });
  };

// Obtener un banner del carrousel por ID
export const getCarrouselBannerById = async (id, token) => {
  const response = await axios.get(`${serverUri}/banners-carrousel/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Crear un nuevo banner en el carrousel
export const createCarrouselBanner = async (data, token) => {
  const response = await axios.post(`${serverUri}/banners-carrousel`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Actualizar un banner existente en el carrousel
export const updateCarrouselBanner = async (id, data, token) => {
  const response = await axios.put(`${serverUri}/banners-carrousel/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Eliminar un banner del carrousel
export const deleteCarrouselBanner = async (id, token) => {
  const response = await axios.delete(`${serverUri}/banners-carrousel/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Alternar la visibilidad de un banner
export const toggleVisibilityBanner = async (id, token) => {
  const response = await axios.patch(`${serverUri}/banners-carrousel/${id}/toggle-visibility/`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Cargar una imagen para el banner del carrousel
export const uploadCarrouselBannerImage = async (file, authToken) => {
  const formData = new FormData();
  formData.append("files", file);

  const response = await fetch(`${serverUri}/upload`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Error al subir la imagen");
  }

  const data = await response.json();
  return data[0]; // Devuelve la información del primer archivo subido
};

// Obtener el carrousel de banners para un usuario específico, ordenado por prioridad
export const getCarrouselForUser = async (userId, token) => {
  const response = await axios.get(`${serverUri}/banners-carrousel/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
