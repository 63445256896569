import { serverUri } from "./serverUri";

const getCuestionario = () => {
  return new Promise((resolve, reject) => {
    fetch(`${serverUri}/preguntas/cuestionario`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createPregunta = (data, authToken) => {
  return fetch(`${serverUri}/preguntas/subir-pregunta`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Pregunta creada con éxito", data);
      return data;
    })
    .catch((error) => {
      console.error("Error al crear la pregunta:", error);
    });
};

const updatePregunta = (id, data, authToken) => {
  return fetch(`${serverUri}/preguntas/${id}`, {
    // Asegúrate de reemplazar :id con el ID real de la pregunta
    method: "PUT",
    headers: {
      "Content-Type": "application/json", // Añade esta línea para especificar el tipo de contenido
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(data), // Convierte el objeto de datos a un string JSON
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("La solicitud falló");
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error al actualizar: ", error);
      throw error;
    });
};

const deletePergunta = (id, authToken) => {
  return fetch(`${serverUri}/preguntas/${id}`, {
    // Asegúrate de reemplazar :id con el ID real de la pregunta
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("La solicitud para eliminar falló");
      }
      return response.json();
    })
    .then((data) => {
      console.log("Pregunta eliminada con éxito", data);
      return data;
    })
    .catch((error) => {
      console.log("Error al eliminar la pregunta: ", error);
      throw error;
    });
};

const updateRespuesta = (id, data, authToken) => {
  return fetch(`${serverUri}/respuestas/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("La solicitud falló");
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error al actualizar: ", error);
      throw error;
    });
};

const deleteRespuesta = (id, authToken) => {
  return fetch(`${serverUri}/respuestas/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("La solicitud para eliminar falló");
      }
      return response.json();
    })
    .then((data) => {
      console.log("Respuesta eliminada con éxito", data);
      return data;
    })
    .catch((error) => {
      console.log("Error al eliminar la respuesta: ", error);
      throw error;
    });
};

export {
  getCuestionario,
  createPregunta,
  updatePregunta,
  deletePergunta,
  updateRespuesta,
  deleteRespuesta,
};
