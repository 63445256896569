import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import AdminLayout from "../components/AdminLayout";
import { Button, Input, message, DatePicker } from "antd";
import {
  startOfDay,
  endOfDay,
  isAfter,
  isBefore,
  parseISO,
  format,
  formatISO,
} from "date-fns";

import { SearchOutlined } from "@ant-design/icons";
import { ModalGeneral } from "../components/modal/ModalGeneral";
import FormAlert from "../components/alertas/FormAlert";
import { getAlerts, deleteAlert } from "../api/alertas";
import { IoFilterSharp } from "@react-icons/all-files/io5/IoFilterSharp";

import pencil from "../assets/icons/pencil.svg";
import trash from "../assets/icons/trash-2.svg";

const { RangePicker } = DatePicker;

const translateDays = (days) => {
  if (!days) return "N/A";
  const validDays = ["L", "M", "MM", "J", "V", "S", "D"];
  const dayMap = {
    L: "L",
    M: "M",
    MM: "MM",
    J: "J",
    V: "V",
    S: "S",
    D: "D",
  };

  const selectedDays = validDays
    .filter((day) => days[day])
    .map((day) => dayMap[day]);

  if (selectedDays.length === 7) return "Todos los días";
  if (selectedDays.length === 5 && !days.S && !days.D) return "Cada Lun-Vie";
  if (selectedDays.length === 2 && days.S && days.D)
    return "Cada fin de semana";

  return selectedDays.length > 0 ? `Cada ${selectedDays.join(", ")}` : "N/A";
};

export const AlertasPromocionales = () => {
  const [loading, setLoading] = useState(true);
  const [dataAlerts, setDataAlerts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [filters, setFilters] = useState({
    searchText: "",
    dateRange: [null, null],
  });

  const handleEdit = (alert) => {
    setOpen(true);
    setSelectedAlert(alert);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar esta alerta?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#90E6D3",
      cancelButtonColor: "#6e7881",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sí, eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const authToken = localStorage.getItem("token");

        await deleteAlert(id, authToken);
        message.success("Alerta eliminada con éxito");
        fetchAlerts();
      }
    });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const applyFilters = () => {
    setFilters({ searchText, dateRange });
  };

  const handleClearFilters = () => {
    setSearchText("");
    setDateRange([null, null]);
    setFilters({ searchText: "", dateRange: [null, null] });
  };

  const onOk = () => {
    setOpen(false);
    setSelectedAlert(null);
    fetchAlerts();
  };

  const onCancel = () => {
    setOpen(false);
    setSelectedAlert(null);
  };

  const fetchAlerts = async () => {
    try {
      const authToken = localStorage.getItem("token");

      setLoading(true);
      const data = await getAlerts(authToken);
      setDataAlerts(Array.isArray(data) ? data : []);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener las alertas:", error);
      message.error("Ocurrió un error al obtener las alertas");
      setDataAlerts([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  // Convertir "YYYY-MM-DD" a formato ISO 8601 con la misma estructura de tiempo
  const convertToISO8601 = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return date;
  };

  const filteredAlerts = dataAlerts.filter((item) => {
    const matchesSearchText =
      item.title.toLowerCase().includes(filters.searchText) ||
      (item.periodicity
        ? item.periodicity.toLowerCase().includes(filters.searchText)
        : false);

    const [startRange, endRange] = filters.dateRange;

    // Convertir las fechas de inicio y fin a formato ISO 8601
    const convertedStartDate = convertToISO8601(item.startDate);
    const convertedEndDate = convertToISO8601(item.endDate);

    // Crear nuevas fechas para ajustarlas sin modificar los valores originales
    const adjustedStartRange = startRange
      ? new Date(startRange._d.getTime())
      : null;
    const adjustedEndRange = endRange ? new Date(endRange._d.getTime()) : null;

    // Añadir un día a las fechas de rango seleccionadas para la comparación
    if (adjustedStartRange) {
      adjustedStartRange.setDate(adjustedStartRange.getDate() - 1);
    }
    if (adjustedEndRange) {
      adjustedEndRange.setDate(adjustedEndRange.getDate() - 1);
    }

    const matchesDateRange =
      !adjustedStartRange ||
      !adjustedEndRange ||
      (convertedStartDate &&
        isAfter(convertedStartDate, startOfDay(adjustedStartRange)) &&
        isBefore(convertedStartDate, endOfDay(adjustedEndRange))) ||
      (convertedStartDate &&
        isAfter(convertedStartDate, startOfDay(adjustedStartRange)) &&
        isBefore(convertedStartDate, endOfDay(adjustedEndRange))) ||
      (convertedStartDate &&
        convertedEndDate &&
        isBefore(convertedStartDate, endOfDay(adjustedEndRange)) &&
        isAfter(convertedEndDate, startOfDay(adjustedStartRange)));

    return matchesSearchText && matchesDateRange;
  });

  return (
    <AdminLayout>
      <section className="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl p-10">
        <h1 className="text-left text-3xl font-bold mb-16">
          Alertas Promocionales
        </h1>
        <div className="w-full flex justify-between mb-4">
          <Input
            placeholder="Buscar..."
            value={searchText}
            onChange={handleSearch}
            style={{
              width: 380,
              marginRight: 8,
              borderRadius: "10px",
              fontSize: "18px",
            }}
            suffix={<SearchOutlined />}
          />
          <Button
            className="shadow-2xl cursor-pointer text-lg text-black hover:text-black"
            type="primary"
            style={{
              border: "none",
              borderRadius: "44px",
              backgroundColor: "#00CEA9",
              fontSize: "18px",
              color: "black",
              width: "280px",
              height: "44px",
              boxShadow: "0 10px 10px -12px rgba(0, 0, 0, 1)",
            }}
            onClick={() => {
              setOpen(true);
              setSelectedAlert(null);
            }}
          >
            Nueva Alerta
          </Button>
        </div>
        <div className="flex justify-start mb-8">
          <RangePicker
            placeholder={["Fecha Inicio", "Fecha Fin"]}
            onChange={handleDateRangeChange}
            value={dateRange}
            format="DD/MM/YYYY"
            style={{ marginRight: 8 }}
          />

          <div
            className="bg-gray-50 border shadow-lg border-slate-300 h-10 cursor-pointer ml-4 mt-2 w-auto rounded-xl flex items-center justify-center px-8"
            onClick={applyFilters}
          >
            <div className="flex items-center">
              <div className="mr-2">
                <IoFilterSharp size={18} />
              </div>
              <div className="text-sm text-[10px] sm:text-[12px] md:text-[14px]">
                <p style={{ margin: "0px" }}>Filtrar</p>
              </div>
            </div>
          </div>

          <div
            className="bg-gray-50 border shadow-lg border-slate-300 h-10 cursor-pointer ml-2 mt-2 w-auto rounded-xl flex justify-center items-center px-8"
            onClick={handleClearFilters}
          >
            <p style={{ margin: "0px" }}>Reset Filtro</p>
          </div>
        </div>
        <h2 className="text-left text-2xl mb-8">Alertas Promocionales</h2>
        <div className="flex flex-wrap justify-start gap-12">
          {filteredAlerts.map((alert) => (
            <div
              key={alert.id}
              className="w-[328px] bg-white shadow-lg rounded-xl overflow-hidden"
            >
              <img
                src={alert.image.url}
                alt="imagen"
                className="w-full h-[281px] object-cover rounded-t-xl"
              />
              <div
                className="px-4 py-2 bg-[#90E6D3] flex flex-col justify-between h-[104.5px] rounded-b-xl text-xs"
                style={{ fontSize: "11px", margin: "0px" }}
              >
                <div className="h-full flex justify-between items-center">
                  <Button
                    onClick={() => handleEdit(alert)}
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    <img
                      src={pencil}
                      alt="Editar"
                      width="22px"
                      style={{ verticalAlign: "middle" }}
                    />
                  </Button>
                  <div className="text-center flex-grow w-16">
                    <p
                      className="font-bold"
                      style={{
                        marginBottom: "2px",
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {alert.title}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Frecuencia: {translateDays(alert.days)}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Fecha Inicio:
                      {alert.startDate
                        ? format(parseISO(alert.startDate), "dd/MM/yyyy")
                        : "N/A"}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Fecha Fin:{" "}
                      {alert.endDate
                        ? format(parseISO(alert.endDate), "dd/MM/yyyy")
                        : "N/A"}
                    </p>
                  </div>

                  <Button
                    onClick={() => handleDelete(alert.id)}
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    <img
                      src={trash}
                      alt="Eliminar"
                      width="18px"
                      style={{ verticalAlign: "middle" }}
                    />
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <ModalGeneral
        open={open}
        title="Nueva Alerta"
        onOk={onOk}
        onCancel={onCancel}
      >
        <FormAlert
          key={JSON.stringify(selectedAlert)}
          onCancel={onCancel}
          alert={selectedAlert}
          onOk={onOk}
        />
      </ModalGeneral>
    </AdminLayout>
  );
};

export default AlertasPromocionales;
