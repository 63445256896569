import React from "react";
import { Modal } from "antd";

export const ModalGeneral = ({ open, title, children, onOk, onCancel }) => {
  return (
    <>
      <Modal
        title={title}
        centered
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
        okText="Aceptar"
        cancelText="Cancelar"
        cancelButtonProps={{
          style: { border: "0", fontSize: "16px", display: "none" },
        }}
        okButtonProps={{
          style: {
            border: "0",
            borderRadius: "20px",
            padding: "10px 30px",
            display: "none",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "16px",
            color: "#000",
          },
        }}
      >
        {children}
      </Modal>
    </>
  );
};
