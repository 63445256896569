import React, { useState, useEffect } from "react";
import {
  createPregunta,
  updatePregunta,
  updateRespuesta,
} from "../../api/cuestionario";

const PreguntaCuestionario = ({
  iteracion,
  preguntaData,
  fetchData,
  handleDeletePregunta,
  authToken
}) => {
  const [showCard, setShowCard] = useState(true);
  const [editCard, setEditCard] = useState(false);
  const [preguntaDataMirror, setPreguntaDataMirror] = useState([]);
  const [originalPreguntaData, setOriginalPreguntaData] = useState([]); // Nuevo estado para almacenar el original
  const letras = ["a", "b", "c", "d", "e"];

  useEffect(() => {
    // Inicializar el estado de preguntaDataMirror con la preguntaData recibida
    setPreguntaDataMirror(preguntaData);
    // Almacenar una copia independiente del estado original
    setOriginalPreguntaData(JSON.parse(JSON.stringify(preguntaData)));
  }, [preguntaData]);

  const handleInputChange = (valor, i) => {
    // Crear una copia del estado actual solo para la respuesta específica
    const updatedRespuesta = { ...preguntaDataMirror.respuestas[i] };

    // Modificar la copia con el nuevo valor
    updatedRespuesta.contenido = valor;

    // Crear una copia de la preguntaDataMirror actual
    const updatedPreguntaDataMirror = { ...preguntaDataMirror };

    // Reemplazar la respuesta específica en la copia de preguntaDataMirror
    updatedPreguntaDataMirror.respuestas[i] = updatedRespuesta;

    // Establecer el nuevo estado
    setPreguntaDataMirror(updatedPreguntaDataMirror);
  };

  const handleTitleChange = (valor) => {
    // Crear una copia de la preguntaDataMirror actual
    const updatedPreguntaDataMirror = { ...preguntaDataMirror };

    // Modificar el título de la pregunta en la copia
    updatedPreguntaDataMirror.pregunta = valor;

    // Establecer el nuevo estado
    setPreguntaDataMirror(updatedPreguntaDataMirror);
  };

  const handleCancelar = () => {
    // Restaurar al estado original almacenado en originalPreguntaData
    setPreguntaDataMirror(JSON.parse(JSON.stringify(originalPreguntaData)));
    setEditCard(false);
  };

  const handleGuardar = async () => {

    const isValid = validateRespuestas();

    if (!isValid) {
      return;
    }

    try {
      if (preguntaData.id) {
        // Actualizar la pregunta existente
        if (preguntaDataMirror.pregunta !== originalPreguntaData.pregunta) {
          await updatePregunta(preguntaData.id, {
            titulo: preguntaDataMirror.pregunta,
          }, authToken);
        }

        // Actualizar respuestas existentes
        for (const [i, respuesta] of preguntaDataMirror.respuestas.entries()) {
          const originalRespuesta = originalPreguntaData.respuestas[i];
          if (
            respuesta.contenido !== originalRespuesta.contenido ||
            respuesta.categoria !== originalRespuesta.categoria
          ) {
            await updateRespuesta(respuesta.id, {
              contenido: respuesta.contenido,
              categoria: respuesta.categoria,
            }, authToken);
          }
        }
      } else {
        // Crear una nueva pregunta
        const nuevaPregunta = {
          pregunta: preguntaDataMirror.pregunta,
          respuestas: preguntaDataMirror.respuestas.map(
            ({ contenido, categoria }) => ({
              contenido,
              categoria,
              // Se omite el campo 'id' aquí
            })
          ),
        };
        await createPregunta(nuevaPregunta, authToken);
      }
    } catch (error) {
      console.error("Error en handleGuardar: ", error);
    }

    setEditCard(false);
    fetchData();
  };

  const validateRespuestas = () => {
    const respuestasSet = new Set(); // Usaremos un conjunto para verificar duplicados
    let hasEmptyRespuestas = false;
    let hasDuplicates = false;
    let errorMessage = "";
  
    if (preguntaDataMirror.pregunta.trim() === "") {
      errorMessage = "La pregunta no puede estar vacía.";
    } else {
      preguntaDataMirror.respuestas.forEach((respuesta) => {
        if (respuesta.contenido.trim() === "") {
          hasEmptyRespuestas = true;
        } else if (respuestasSet.has(respuesta.contenido)) {
          hasDuplicates = true;
        } else {
          respuestasSet.add(respuesta.contenido);
        }
      });
  
      if (hasEmptyRespuestas) {
        errorMessage = "No se permiten respuestas vacías.";
      }
  
      if (hasDuplicates) {
        errorMessage = "No se permiten respuestas duplicadas.";
      }
    }
  
    if (errorMessage !== "") {
      // Mostrar el mensaje de error
      alert(errorMessage);
      return false;
    }
  
    return true; // La pregunta no está vacía y no hay respuestas duplicadas ni vacías
  };
  
  

  return (
    <section className="text-left mb-8">
      <h2 className="ml-4">Pregunta {iteracion + 1}</h2>
      <div className="p-4 bg-gray-300 border border-black rounded-xl">
        <div className="flex justify-between items-center">
          {!editCard ? (
            <h3 className={`${showCard ? "mb-4" : ""}`}>
              {preguntaDataMirror.pregunta}
            </h3>
          ) : (
            <input
              type="text"
              onChange={(e) => handleTitleChange(e.target.value)}
              className="mb-1 bg-white px-2 w-60 font-semibold"
              value={preguntaDataMirror.pregunta}
            />
          )}

          <div className="flex">
            {!editCard && (
              <button className="mr-8" onClick={() => setShowCard(!showCard)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                </svg>
              </button>
            )}
            {showCard && !editCard && (
              <>
                <button className="mr-8" onClick={() => setEditCard(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z"
                      fill="#008000"
                    />
                  </svg>
                </button>
                <button
                  className="mr-8"
                  onClick={() =>
                    handleDeletePregunta(iteracion, preguntaDataMirror.id)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M3 6v18h18v-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.731 2 1.631 2h5.712z"
                      fill="#A52A2A"
                    />
                  </svg>
                </button>
              </>
            )}
            {editCard && (
              <>
                <button
                  className="px-16 py-1 shadow-md mx-4 bg-[#353535] text-white rounded-xl hover:bg-[#363636] hover:shadow-md transition-all duration-300"
                  onClick={() => handleCancelar()}
                >
                  Cancelar
                </button>
                <button
                  className="px-16 py-1 shadow-md mx-4 bg-[#00CEA9] text-[#353535] rounded-xl hover:bg-[#00C4A2] hover:shadow-md transition-all duration-300"
                  onClick={() => handleGuardar()}
                >
                  Guardar
                </button>
              </>
            )}
          </div>
        </div>
        {preguntaDataMirror && preguntaDataMirror.respuestas ? (
          <div>
            {preguntaDataMirror.respuestas.map((respuesta, i) => (
              <div className="flex gap-4" key={i}>
                {editCard ? (
                  <>
                    <input
                      type="text"
                      onChange={(e) => handleInputChange(e.target.value, i)}
                      className="mb-1 bg-white px-2 w-60"
                      value={respuesta.contenido}
                    />
                    <p className="mb-1 bg-white px-2 w-20 rounded-lg text-center">
                      {respuesta.categoria}
                    </p>
                  </>
                ) : (
                  showCard && (
                    <div>
                      <p className="mb-1">
                        {letras[i] + ") " + respuesta.contenido}
                      </p>
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default PreguntaCuestionario;
