import React, { useState } from "react";
import imageIconRed from "../../images/image_red.svg";
import imageIcon from "../../images/image.svg";
import ConfirmationModal from "./ConfirmationModal";
import { addOnboarding, updateOnboarding } from "../../api/onboarding";

const ContentModal = ({
  titulo,
  isEdit,
  onGuardar,
  onDescartar,
  isCamposLlenos,
  editData,
  gifImage,
  idCategory,
  onboardingDataArray,
}) => {
  console.log(editData);
  const [title, setTitle] = useState(isEdit ? editData.titulo : "");
  const [descripcion, setDescripcion] = useState(
    isEdit ? editData.descripcion : ""
  );
  const [archivo, setArchivo] = useState(null);
  const [titleValid, setTitleValid] = useState(true);
  const [descripcionValid, setDescripcionValid] = useState(true);
  const [archivoValid, setArchivoValid] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false); // Step 1

  // Agregar un nuevo estado para la imagen cargada
  const [imagenCargada, setImagenCargada] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Estado para mostrar/ocultar el modal de confirmación
  const [confirmModalProps, setConfirmModalProps] = useState({
    pregunta: "", // Pregunta en el modal de confirmación
    descripcion: "", // Descripción en el modal de confirmación
    textoBotonConfirmar: "", // Texto del botón Confirmar en el modal de confirmación
    textoBotonCancelar: "", // Texto del botón Cancelar en el modal de confirmación
  });

  const handleGuardarModal = () => {
    if (!isEdit) {
      setConfirmModalProps({
        pregunta: "¿Deseas guardar esta pantalla?",
        descripcion: "Se agregará al Onboarding al instante.",
        textoBotonConfirmar: "Guardar",
        textoBotonCancelar: "Cancelar",
      });
      setShowConfirmModal(true);
    } else {
      setConfirmModalProps({
        pregunta: "¿Deseas actualizar esta pantalla?",
        descripcion: "Los cambios se actualizarán al instante.",
        textoBotonConfirmar: "Guardar",
        textoBotonCancelar: "Cancelar",
      });
      setShowConfirmModal(true);
    }
  };

  const handleGuardar = () => {
    const authToken = localStorage.getItem("token");
    // Validar campos
    setArchivoValid(gifImage ? true : false || archivo);
    let isTitleValid = title.trim() !== "";
    let isDescripcionValid = descripcion.trim() !== "";
    setTitleValid(isTitleValid);
    setDescripcionValid(isDescripcionValid);
    if (isTitleValid && isDescripcionValid) {
      if (isEdit) {
        // Comprueba si el archivo ha cambiado
        if (archivo !== gifImage.url) {
          // El archivo ha cambiado, realiza la carga del nuevo GIF y actualiza la referencia en la base de datos
          const formData = new FormData();
          formData.append("files.gif", archivo); // Envia el archivo GIF
          formData.append(
            "data",
            `{"titulo": "${title}", "descripcion": "${descripcion}"}`
          );
          updateOnboarding(formData, authToken, editData.id)
            .then((response) => {
              // Maneja la respuesta exitosa del servidor aquí
              onGuardar(); // Llama a onGuardar aquí
            })
            .catch((error) => {
              console.log("Error al actualizar: ", error);
              onGuardar();
            });
        } else {
          // El archivo no ha cambiado, realiza la solicitud de actualización al servidor
          onGuardar(); // Llama a onGuardar aquí
        }
      } else {
        // El archivo ha cambiado, realiza la carga del nuevo GIF y actualiza la referencia en la base de datos
        const highestPriority = Math.max(
          ...onboardingDataArray.map((item) => item.priority),
          0 // Valor predeterminado si no hay elementos en onboardingDataArray
        );
        const nextPriority = highestPriority + 1 || 1; // Establece el valor predeterminado a 1 si no hay elementos en onboardingDataArray
  
        const formData = new FormData();
        formData.append("files.gif", archivo); // Envia el archivo GIF
        formData.append(
          "data",
          `{"titulo": "${title}", "descripcion": "${descripcion}", "priority": ${nextPriority}, "onboarding": "${idCategory}"}`
        );
  
        addOnboarding(formData, authToken)
          .then((response) => {
            // Maneja la respuesta exitosa del servidor aquí
            onGuardar(); // Llama a onGuardar aquí
          })
          .catch((error) => {
            console.log("Error al actualizar: ", error);
            onGuardar();
          });
        // Muestra el modal de confirmación de "Guardar"
      }
      onDescartar();
    }
    // No es necesario llamar a onGuardar aquí, ya que ya se llamó en los bloques anteriores
  };

  const handleDescartar = () => {
    // Muestra el modal de confirmación de "Descartar"
    setConfirmModalProps({
      pregunta: "¿Deseas descartar los cambios en esta pantalla?",
      descripcion: "No se podrá recuperar la información.",
      textoBotonConfirmar: "Descartar",
      textoBotonCancelar: "Cancelar",
    });
    setShowConfirmModal(true);
  };

  // Función para manejar la carga de un archivo
  const handleFileUpload = (file) => {
    setArchivo(file);
    setArchivoValid(true);
    // Actualizar la imagen cargada
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagenCargada(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center text-black z-50">
      <div className="fixed inset-0 bg-black opacity-50 z-40 " />

      <div className=" bg-white p-4 rounded-md shadow-lg justify-between relative z-50 font-bold text-lg lg:w-[1000px] mx-8">
        <div className="px-16">
          <h2 className="font-semibold text-black text-2xl py-4">{titulo}</h2>

          <div className="mt-4 text-start flex flex-wrap gap-4 lg:gap-0">
            <div className="w-full lg:w-1/2 lg:px-2 flex flex-row flex-col">
              <label className="pb-4">Título de la pantalla</label>

              <textarea
                type="text"
                placeholder="Título de la pantalla..."
                className={`p-3 lg:h-[100px] w-full border resize-none text-base font-normal ${
                  titleValid ? "border-gray-300" : "border-red-500"
                } rounded-md bg-[#C4C4C480]`}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="w-full lg:w-1/2 lg:px-2 flex flex-col">
              <label className="pb-4">Descripción</label>

              <textarea
                type="text"
                placeholder="Descripción de la pantalla..."
                className={`p-3 lg:h-[100px] w-full border resize-none text-base font-normal ${
                  descripcionValid ? "border-gray-300" : "border-red-500"
                } rounded-md bg-[#C4C4C480]`}
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </div>
          </div>

          <div>
            <h3 className="mt-4 text-start font-bold text-lg text-black">
              Agregar contenido
            </h3>

            <div
              className={`bg-[#C4C4C480] border-dashed border-2 rounded-md p-4 mt-2 h-[150px] lg:h-[270px] text-center flex flex-col justify-center items-center cursor-pointer ${
                archivoValid ? "border-black" : "border-red-500"
              }`}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files[0];
                // Realizar el manejo del archivo seleccionado (por ejemplo, guardar en el estado)
                handleFileUpload(file);
              }}
              // En el evento de hacer clic para seleccionar un archivo
              onClick={() => {
                const fileInput = document.createElement("input");
                fileInput.type = "file";
                fileInput.accept = ".jpg, .jpeg, .png, .gif";
                fileInput.style.display = "none";
                document.body.appendChild(fileInput);

                fileInput.addEventListener("change", (e) => {
                  const file = e.target.files[0];
                  // Realizar el manejo del archivo seleccionado (por ejemplo, guardar en el estado)
                  handleFileUpload(file);
                  document.body.removeChild(fileInput);
                });

                fileInput.click();
              }}
            >
              <div className="flex flex-col items-center">
                {imagenCargada ? (
                  <img
                    src={imagenCargada}
                    alt="Imagen Cargada"
                    className="mx-auto my-4 max-h-[150px]"
                  />
                ) : gifImage ? (
                  <img
                    src={gifImage.url} // Mostrar la imagen del prop gifImage
                    alt="Imagen GIF"
                    className="mx-auto my-4 max-h-[150px]"
                  />
                ) : (
                  <img
                    src={archivoValid ? imageIcon : imageIconRed}
                    alt="Image Icon"
                    className="mx-auto my-4 max-h-[150px]"
                  />
                )}
                <p
                  className={`text-sm lg:text-lg ${
                    archivoValid ? "" : "text-red-500"
                  }`}
                >
                  {archivoValid
                    ? "Arrastra o haz clic para seleccionar un archivo multimedia (GIF)"
                    : "Por favor, selecciona un archivo multimedia (GIF)"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center px-8 mt-4">
          <button
            onClick={handleDescartar}
            className="bg-[#353535] text-white p-2 rounded-full mr-2 px-16 shadow-md"
          >
            Descartar
          </button>
          <button
            onClick={handleGuardarModal}
            className={`${
              isCamposLlenos() ? "bg-[#00CEA9] text-[#002821]" : "bg-gray-400"
            } p-2 rounded-full px-16 shadow-md`}
          >
            Guardar
          </button>
        </div>
      </div>

      {/* Modal de confirmación */}
      {showConfirmModal && (
        <ConfirmationModal
          pregunta={confirmModalProps.pregunta}
          descripcion={confirmModalProps.descripcion}
          textoBotonConfirmar={confirmModalProps.textoBotonConfirmar}
          textoBotonCancelar={confirmModalProps.textoBotonCancelar}
          onConfirm={(confirmed) => {
            setShowConfirmModal(false);
            if (confirmed) {
              // Realiza la acción correspondiente (Guardar o Descartar)
              if (confirmModalProps.textoBotonConfirmar === "Guardar") {
                // Realizar la petición de creación aquí
                handleGuardar();
              } else if (
                confirmModalProps.textoBotonConfirmar === "Descartar"
              ) {
                onDescartar();
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default ContentModal;
