import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import AdminLayout from "../components/AdminLayout";
import { Table, Tooltip, Button, Space, Input, message } from "antd";
import { format } from "date-fns";
import { columnsNotifications } from "../utils/columns/columns";
import pencil from "../assets/icons/pencil.svg";
import trash from "../assets/icons/trash.svg";
import { SearchOutlined } from "@ant-design/icons";
import { ModalGeneral } from "../components/modal/ModalGeneral";
import { FormNotification } from "../components/formulario/FormNotification";
import { deleteNotification, getNotifications } from "../api/notifications";

export const ProgramarNotificaciones = () => {
  const [loading, setLoading] = useState(true);
  const [dataNotifications, setDataNotifications] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [idFirebase, setIdFirebase] = useState(0);
  const [showDate, setShowDate] = useState(false);

  const handleEdit = (id) => {
    setOpen(true);
    setIdFirebase(id);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar esta notificación?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00CEA9",
      cancelButtonColor: "#6e7881",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sí, eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const authToken = localStorage.getItem("token");

        await deleteNotification(id, authToken);
        message.success("Notificación eliminada con éxito");
        // Refrescar la lista de notificaciones
        fetchNotifications();
      }
    });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const onOk = () => {
    setOpen(false);
  };
  const onCancel = () => {
    setOpen(false);
  };

  const fetchNotifications = async () => {
    try {
      const authToken = localStorage.getItem("token");

      setLoading(true);
      const data = await getNotifications(authToken);
      setDataNotifications(Array.isArray(data) ? data : []);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener las notificaciones:", error);
      message.error("Ocurrió un error al obtener las notificaciones");
      setDataNotifications([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  // Filtra las notificaciones en base al texto de búsqueda
  const filteredNotifications = dataNotifications.filter((item) =>
    Object.values(item).some(
      (value) => value && value.toString().toLowerCase().includes(searchText)
    )
  );

  return (
    <AdminLayout>
      <section className="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl p-10">
        <h1 className="text-left text-3xl font-bold">Notificaciones</h1>
        <div className="w-full flex justify-end">
          <Button
            className="rounded-full shadow-2xl text-sc-titles  cursor-pointer mb-10 w-auto h-auto text-base text-black p-2 px-7 hover:text-black"
            type="primary"
            style={{ border: "none" }}
            onClick={() => {
              setOpen(true);
              setIdFirebase(0);
              setShowDate(false);
            }}
          >
            Nueva notificación
          </Button>
        </div>
        <Input
          placeholder="Buscar "
          value={searchText}
          onChange={handleSearch}
          style={{ width: 200, marginRight: 8, borderRadius: "10px" }}
          prefix={<SearchOutlined />}
        />
        <Table
          className="mt-4 cursor-pointer"
          dataSource={filteredNotifications}
          columns={columnsNotifications.map((col) => {
            if (col.key === "actions") {
              return {
                ...col,
                render: (text, record) => (
                  <Space
                    size="middle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() => handleDelete(record.id)}
                      style={{ border: "none" }}
                    >
                      <img src={trash} alt="logo" width="20" />
                    </Button>
                    <Button
                      onClick={() => handleEdit(record.id)}
                      style={{ border: "none" }}
                    >
                      <img src={pencil} alt="logo" width="20" />
                    </Button>
                  </Space>
                ),
              };
            }
            return col;
          })}
          size="small"
          rowKey={(record) => record.id}
          loading={loading}
          scroll={{ x: 1300 }}
          pagination={{ showSizeChanger: true }}
        />
      </section>
      <ModalGeneral
        open={open}
        title="Nueva notificación"
        onOk={onOk}
        onCancel={onCancel}
      >
        <FormNotification
          onCancel={onCancel}
          idFirebase={idFirebase}
          showDate={showDate}
          setShowDate={setShowDate}
        />
      </ModalGeneral>
    </AdminLayout>
  );
};
