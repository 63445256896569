import * as React from "react";
import { useState } from "react";
import AdminLayout from "../components/AdminLayout";
import { Table, Input, DatePicker, Checkbox, message } from "antd";
import { Row, Col } from "antd";
import {
  SearchOutlined,
  MinusOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { IoFilterSharp } from "@react-icons/all-files/io5/IoFilterSharp";
import { getUsersCuestionario, getCuestionario } from "../api/users";
import SCButton from "../components/SCButton";
import {
  formatoFecha,
  downloadExcelFile,
} from "./../components/helper/utilidades";
import moment from "moment";

const ReporteCuestionarios = () => {
  const [usersData, setUsersData] = useState([]);
  const [userDataMounth, setUsersDataMounth] = useState([]);
  const [cuestionarioData, setCuestionarioData] = useState([]);
  const [showFechaFilter, setShowFechaFilter] = useState(false);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [preguntasData, setPreguntasData] = useState([]);
  const [filterByGustos, setFilterByGustos] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const preguntaHeaders = preguntasData.map((pregunta) => ({
    title: pregunta.titulo,
    dataIndex: pregunta.id,
    key: pregunta.id,
    width: 100,
    render: (text) => <p className="text-xs">{text}</p>,
    sorter: (a, b) => a[pregunta.id].localeCompare(b[pregunta.id]),
    sortDirections: ["ascend", "descend", "ascend"],
  }));

  const handleGustosCheckbox = (e) => {
    setFilterByGustos(e.target.checked);
    if (e.target.checked) {
      const filteredData = cuestionarioData.filter(
        (item) => item.gustos && Object.keys(item.gustos).length > 0
      );
      setCuestionarioData(filteredData);
    } else {
      // Restablece los datos o aplica otros filtros que tengas
      setCuestionarioData(userDataMounth);
      if (fechaInicio && fechaFin) {
        handleFiltar();
      }
    }
  };

  const columnasExistentes = [
    {
      title: "Nombre del usuario",
      dataIndex: "nombre",
      key: "nombre",
      // fixed: 'left',
      width: 100,
      render: (text) => <p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        if ((a.nombre !== undefined) & (a.nombre !== null)) {
          if ((b.nombre !== undefined) & (b.nombre !== null)) {
            return a.nombre.localeCompare(b.nombre);
          } else {
            return a.nombre.localeCompare("Sin Respuesta");
          }
        } else {
          if ((b.nombre !== undefined) & (b.nombre !== null)) {
            return "Sin respuesta".localeCompare(b.apellido);
          } else {
            return "Sin respuesta".localeCompare("Sin respuesta");
          }
        }
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Apellido del usuario",
      dataIndex: "apellido",
      key: "apellido",
      // fixed: 'left',
      width: 100,
      render: (text) => <p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        if ((a.apellido !== undefined) & (a.apellido !== null)) {
          if ((b.apellido !== undefined) & (b.apellido !== null)) {
            return a.apellido.localeCompare(b.apellido);
          } else {
            return a.apellido.localeCompare("Sin Respuesta");
          }
        } else {
          if ((b.apellido !== undefined) & (b.apellido !== null)) {
            return "Sin respuesta".localeCompare(b.apellido);
          } else {
            return "Sin respuesta".localeCompare("Sin respuesta");
          }
        }
      },
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Número de usuario",
      dataIndex: "id",
      key: "userId",
      // fixed: 'left',
      width: 100,
      render: (text) => <p className="text-xs w-16">{text}</p>,
      sorter: (a, b) => a.id.localeCompare(b.id),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Fecha creación cuestionario",
      dataIndex: "createdAt",
      key: "fecha",
      width: 120,
      render: (text) => <p className="text-xs">{text}</p>,
      sorter: (a, b) => {
        const fechaA =
          a.createdAt === "Sin respuesta"
            ? 0
            : moment(a.createdAt, "DD/MM/YYYY").unix();
        const fechaB =
          b.createdAt === "Sin respuesta"
            ? 0
            : moment(b.createdAt, "DD/MM/YYYY").unix();
        return fechaA - fechaB;
      },
      sortDirections: ["descend", "ascend"],
    },
  ];

  const columns = [...columnasExistentes, ...preguntaHeaders];

  const traducirRespuestas = (gustos) => {
    const respuestasTraducidas = {};
  
    // Verificar que preguntasData tenga un valor válido
    if (preguntasData && preguntasData.length > 0) {
      // Primero, asignamos "Sin respuesta" a todas las preguntas
      preguntasData.forEach((pregunta, index) => {
        respuestasTraducidas[pregunta.id] = "Sin respuesta";
      });
  
      // Luego, si hay gustos, actualizamos las respuestas
      if (gustos && gustos.gustos) {
        gustos.gustos.forEach((gusto) => {
          // Aseguramos que el índice de la pregunta y respuesta estén dentro de los rangos válidos
          if (gusto.pregunta <= preguntasData.length) {
            const pregunta = preguntasData[gusto.pregunta - 1];
            if (pregunta && pregunta.respuestas && gusto.respuesta <= pregunta.respuestas.length) {
              const respuesta = pregunta.respuestas[gusto.respuesta - 1];
              respuestasTraducidas[pregunta.id] = respuesta
                ? respuesta.contenido
                : "Sin respuesta";
            }
          }
        });
      }
    } else {
      // Manejar el caso en que preguntasData no está definido o está vacío
      return respuestasTraducidas;
    }
  
    return respuestasTraducidas;
  };
  

  //consumo de informacion por mes actual
  React.useEffect(() => {
    // getUsers()

    getUsersCuestionario()
      .then((response) => {
        setUsersDataMounth(response);
        setUsersData(response);
        setCuestionarioData(response);
        // console.log(response)
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    getCuestionario()
      .then((response) => {
        setPreguntasData(response);
      })
      .catch((error) => {
        console.log(error);
      });

    //// cargar los usuarios
  }, [actualizar]);
  //consumo de toda la informacion

  const handleDateStart = (value, valueSting) => {
    setFechaInicio(value);
  };

  const handleDateFin = (value, valueSting) => {
    setFechaFin(value);
  };

  const dataSource = cuestionarioData.map((data) => {
    const fechaCuestionario =
      data.gustos && data.gustos.timestamp
        ? moment(data.gustos.timestamp).format("DD/MM/YYYY")
        : "Sin respuesta";

    return {
      ...data,
      key: data.id,
      createdAt: fechaCuestionario,
      ...traducirRespuestas(data.gustos),
    };
  });

  const handleSearch = (e) => {
    console.log(e);
    setValueSearch(e);
    let filter = usersData.filter((item) => {
      //item.nombre.toLowerCase().includes(e.toLocaleLowerCase())
      if (item.nombre !== undefined) {
        return item.nombre.toLowerCase().includes(e.toLowerCase());
      }
    });
    //console.log(filter)
    setCuestionarioData(filter);
  };

  const handleFiltar = () => {
    setShowFechaFilter(false);
    if (!fechaInicio || !fechaFin) {
      message.error("No se seleccionaron las fechas");
      return;
    }
    setLoading(true);

    if (fechaFin === "" && fechaInicio === "") {
      messageApi.open(
        {
          type: "error",
          content: "No se seleccionaron las fechas",
        },
        2000
      );
    } else {
      let inicioDelDia = moment(fechaInicio).startOf("day").add(6, "hours");
      let finDelDia = moment(fechaFin).endOf("day").add(6, "hours");

      const filteredData = userDataMounth.filter((item) => {
        const fechaCuestionario =
          item.gustos && item.gustos.timestamp
            ? new Date(item.gustos.timestamp)
            : null;

        if (fechaCuestionario) {
          return moment(fechaCuestionario).isBetween(inicioDelDia, finDelDia);
        }
        return false;
      });

      setCuestionarioData(filteredData);
      setLoading(false);
    }
  };

  const handleCleanFilters = () => {
    setCuestionarioData(userDataMounth);
    setFechaFin("");
    setFechaInicio("");
    HideFiltros();
    setValueSearch("");
    setUsersData(userDataMounth);
    setFilterByGustos(false);
  };

  const handleSelect = (record, selected) => {
    // console.log(record)
    // console.log(selected)
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.key]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.key);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) =>
      keys.length === cuestionarioData.length
        ? []
        : cuestionarioData.map((r) => r.id)
    );
  };
  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 &&
        selectedRowKeys.length < cuestionarioData.length
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys,
    fixed: true,
    //onChange: onSelectChange,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  };

  const handleGenerateExcel = () => {
    if (selectedRowKeys.length > 0) {
      // Filtrar los datos seleccionados
      const dataToExport = dataSource.filter((item) =>
        selectedRowKeys.includes(item.key)
      );

      // Preparar los headers basados en las columnas de la tabla
      const headers = columns.map((col) => ({
        label: col.title, // El título de la columna
        value: col.dataIndex, // La clave de los datos
      }));

      // Preparar los datos para el Excel
      const dataForExcel = dataToExport.map((row) => {
        const rowData = {};
        headers.forEach((header) => {
          rowData[header.label] = row[header.value];
        });
        return rowData;
      });

      // Generar el título del archivo con la fecha y hora actual
      const timestamp = moment().format("YYYY-MM-DDTHH_mm_ssZ");
      const fileName = `ReporteCuestionarios${timestamp}`;

      // Función para descargar el archivo Excel
      downloadExcelFile(dataForExcel, fileName);
    } else {
      messageApi.open({
        type: "error",
        content: "No tienes ningún registro seleccionado.",
        duration: 2,
      });
    }
  };

  const handleActualizar = () => {
    console.log("actualizando..");
    setUsersData([]);
    setCuestionarioData([]);
    setUsersDataMounth([]);
    setLoading(true);
    setActualizar(!actualizar);
    handleCleanFilters();
  };

  const HideFiltros = () => {
    setShowFechaFilter(false);
  };

  return (
    <AdminLayout>
      {contextHolder}
      <Row justify="start">
        <Col span={12} sm={18} md={12} lg={8}>
          <h3 className="mb-8 text-sc-black font-bold text-[24px] text-left md:text-[32px] ">
            Reporte Cuestionario
          </h3>
        </Col>
        <Col span={12} sm={6} md={12} lg={16}>
          <Row justify="end">
            <Col className="mr-2">
              <SCButton
                className="text-sm rounded"
                onClick={handleGenerateExcel}
              >
                Exportar a Excel
              </SCButton>
            </Col>
            <Col className="mt-2 sm:mt-0 md:mt-0 lg:mt-0">
              <SCButton className="text-sm rounded " onClick={handleActualizar}>
                Actualizar
              </SCButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={14} sm={10} md={8} lg={6}>
          <Input
            placeholder="Buscar..."
            allowClear
            value={valueSearch}
            onChange={(e) => {
              handleSearch(e.target.value);
              //setSearchValue(e.target.value)
            }}
            style={{
              borderRadius: "10px",
            }}
            suffix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Checkbox
            onChange={handleGustosCheckbox}
            checked={filterByGustos}
            style={{
              marginLeft: "20px",
            }}
          >
            Filtrar por cuestionarios contestados
          </Checkbox>
        </Col>
        <Col xs={8} sm={10}>
          <div
            className="bg-gray-50 border visible md:invisible lg:invi shadow-lg border-slate-300 cursor-pointer ml-2 px-2 md:px-1 lg:px-1 md:ml-4   w-auto lg:w-1/2 md:w-1/2 rounded-xl flex justify-center"
            onClick={() => {
              handleCleanFilters();
            }}
          >
            {/* <div className="mt-2 -mb-1">
                <IoFilterSharp size={22}/>
              </div> */}
            <div className=" mt-1 -mb-1">
              <p className="text-xs">Reset Filtro</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={14} sm={10} md={8} lg={6}>
          <div
            className="bg-gray-50 mt-2 cursor-pointer h-auto w-full shadow-lg border flex justify-between border-slate-400 rounded-xl"
            onClick={() => setShowFechaFilter(!showFechaFilter)}
          >
            <div className=" ml-4 mt-1 ">
              <p className="text-xs text-left">Fecha de creación:</p>
              <p className="text-xs text-left font-bold -mt-4">
                {fechaInicio === "" && fechaFin === ""
                  ? "Selecionar Fecha..."
                  : `${formatoFecha(moment(fechaInicio)).replaceAll(
                      "-",
                      "/"
                    )} - ${formatoFecha(moment(fechaFin)).replaceAll(
                      "-",
                      "/"
                    )}`}
              </p>
            </div>
            <div className="mr-4 mt-2 ">
              <CaretDownOutlined />
            </div>
          </div>
          {showFechaFilter ? (
            <div className="  absolute z-20 -mt-4 mb-4  w-full border-b border-l border-r border-gray-400  rounded-b-xl bg-gray-50   ">
              <div className=" flex justify-around border-t mt-2 border-slate-300">
                <div className="w-1/3 ">
                  <p className="text-left">Desde:</p>
                </div>
                <div className="w-1/3 ">
                  <p className="text-left">Hasta:</p>
                </div>
              </div>
              <div>
                <div className="flex justify-around -mt-4 mb-2 px-2">
                  <div>
                    <DatePicker
                      onChange={handleDateStart}
                      suffixIcon={""}
                      placeholder={"seleccionar"}
                      format={"DD/MM/YYYY"}
                      style={{
                        width: "auto",
                        borderRadius: "10px",
                        backgroundColor: "#DADADA ",
                      }}
                    />
                  </div>
                  <div>
                    <MinusOutlined />
                  </div>
                  <div>
                    <DatePicker
                      onChange={handleDateFin}
                      suffixIcon={""}
                      placeholder={"seleccionar"}
                      format={"DD/MM/YYYY"}
                      style={{
                        width: "auto",
                        borderRadius: "10px",
                        backgroundColor: "#DADADA ",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Col>
        <Col xs={8} sm={10} md={8} lg={8} xl={6}>
          <div className=" md:flex flex-row ">
            <div
              className="bg-gray-50 border shadow-lg border-slate-300 cursor-pointer ml-2 px-2 md:px-1 lg:px-1 md:ml-4 mt-2 w-auto md:w-1/2 lg:w-1/2  rounded-xl flex justify-center"
              onClick={() => {
                handleFiltar();
              }}
            >
              <div className="mt-2 -mb-1">
                <IoFilterSharp size={22} />
              </div>
              <div className="ml-2 p-1 mt-1 -mb-1">
                <p>Filtrar</p>
              </div>
            </div>

            <div
              className="bg-gray-50 border invisible md:visible shadow-lg border-slate-300 cursor-pointer ml-2 px-2 md:px-1 lg:px-1 md:ml-4  mt-2 w-auto lg:w-1/2 md:w-1/2 rounded-xl flex justify-center"
              onClick={() => {
                handleCleanFilters();
              }}
            >
              {/* <div className="mt-2 -mb-1">
                <IoFilterSharp size={22}/>
              </div> */}
              <div className=" p-1 mt-1 -mb-1">
                <p>Reset Filtro</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="mt-4 cursor-pointer"
        rowSelection={rowSelection}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record) => record.key}
        size="small"
        loading={loading}
        scroll={{
          x: 1300,
        }}
        pagination={{ showSizeChanger: true }}
      />
    </AdminLayout>
  );
};

export default ReporteCuestionarios;
