import React, { useState, useEffect } from "react";
import AdminLayout from "../components/AdminLayout";
import OnboardingCategory from "../components/onboarding/OnboardingCategory";
import OnboardingItem from "../components/onboarding/OnboardingItem";
import ContentModal from "../components/onboarding/ContentModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  getNamesOnboarding,
  getItemsOnboarding,
  updateOnboarding,
} from "../api/onboarding";
import addIcon from "../images/add_icon.svg";

const Onboardings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [onboardingData, setOnboardingData] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState(null); // State to store data for the selected card
  const [selectedTitle, setSelectedTitle] = useState(null); // State to store data for the selected card
  const [selectedCardId, setSelectedCardId] = useState(null); // State to store data for the selected card
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);
  const [orderedItems, setOrderedItems] = useState([]);
  const [sortedItems, setSortedItems] = useState([]);

  const closeModal = () => {
    setIsContentModalOpen(false);
    setIsLoading(true);
    // Make a new request to getItemsOnboarding to update the data
    getItemsOnboarding(selectedCardId) // Use the appropriate ID or data
      .then((response) => {
        setSelectedCardData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleGuardar = () => {
    setIsContentModalOpen(false);
    setIsLoading(true);
    // Make a new request to getItemsOnboarding to update the data
    getItemsOnboarding(selectedCardId) // Use the appropriate ID or data
      .then((response) => {
        setSelectedCardData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const openModal = () => {
    setIsContentModalOpen(true);
  };

  const isCamposLlenos = () => {
    // Implementa la lógica para verificar si los campos están llenos aquí
    return true; // Cambia esto según tus requisitos
  };

  const updateOnboardingData = () => {
    setIsLoading(true);
    // Make a new request to getItemsOnboarding to update the data
    getItemsOnboarding(selectedCardId) // Use the appropriate ID or data
      .then((response) => {
        setSelectedCardData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleShowOnboarding = (cardId, title) => {
    setSelectedCardId(cardId);
    setSelectedTitle(title);
    setSelectedCardData(null); // Clear previous card data
    setIsLoading(true);
    getItemsOnboarding(cardId)
      .then((response) => {
        setSelectedCardData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleClearCardData = () => {
    setSelectedCardData(null);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return; // No se cambió la posición, no hagas nada
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    // Obtén las tarjetas afectadas por el cambio de posición
    const draggedItem = sortedItems[startIndex];
    const targetItem = sortedItems[endIndex];

    // Calcula las nuevas prioridades
    const newDraggedPriority = new FormData();
    newDraggedPriority.append("data", `{"priority": ${targetItem.priority}}`);

    const newTargetPriority = new FormData();
    newTargetPriority.append("data", `{"priority": ${draggedItem.priority}}`);

    // Realiza una solicitud PUT para actualizar la prioridad del elemento arrastrado
    const authToken = localStorage.getItem("token");
    const updateDragged = updateOnboarding(
      newDraggedPriority,
      authToken,
      draggedItem.id
    );
    const updateTarget = updateOnboarding(
      newTargetPriority,
      authToken,
      targetItem.id
    );

    // Utiliza Promise.all para esperar a que ambas solicitudes se completen
    Promise.all([updateDragged, updateTarget])
      .then(() => {
        // Ambas solicitudes se han completado
        // Ahora puedes actualizar los datos
        updateOnboardingData();
      })
      .catch((error) => {
        console.error("Error en la actualización:", error);
      });

    // Copia de los elementos en el estado sortedItems para evitar mutar el estado directamente
    const updatedSortedItems = [...sortedItems];

    // Reordena los elementos en la copia de sortedItems
    const [removed] = updatedSortedItems.splice(startIndex, 1);
    updatedSortedItems.splice(endIndex, 0, removed);

    // Actualiza el estado sortedItems con los elementos reordenados
    setSortedItems(updatedSortedItems);

    // Actualiza el estado orderedItems con el nuevo orden de prioridades
    const newOrderedItems = updatedSortedItems.map((item, index) => ({
      id: item._id,
      priority: index,
    }));
    setOrderedItems(newOrderedItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simula una carga de datos
        setIsLoading(true);

        getNamesOnboarding()
          .then((response) => {
            setOnboardingData(response);
            // console.log(response)
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
        setIsLoading(false); // Cambia el estado a false en caso de error
      }
    };

    fetchData();
  }, []);

  // Dentro del useEffect o cualquier otra función donde obtienes los datos de selectedCardData
  useEffect(() => {
    // Resto del código para obtener los datos de selectedCardData
    const itemsSorted = selectedCardData?.sort(
      (a, b) => a.priority - b.priority
    );

    // Ordenar los elementos por la propiedad priority de menor a mayor
    setSortedItems(itemsSorted);

    // Almacenar el orden en el estado orderedItems
    setOrderedItems(
      itemsSorted?.map((item) => ({ id: item._id, priority: item.priority }))
    );

    // Resto del código del useEffect
  }, [selectedCardData]);

  return (
    <AdminLayout>
      {(!sortedItems || isLoading) && (
        <div>
          <h2 className="mb-8 text-sc-black font-bold text-[24px] text-left md:text-[32px]">
            Configuración de Onboardings
          </h2>
          {isLoading ? (
            <p>Cargando...</p>
          ) : showOnboarding ? (
            <div>
              <h1
                className="cursor-pointer"
                onClick={() => handleShowOnboarding()}
              >
                onboarding
              </h1>
            </div>
          ) : (
            <div className="flex gap-3">
              {onboardingData.map((item) => (
                <OnboardingCategory
                  key={item._id}
                  onboardingData={item}
                  handleShowOnboarding={() =>
                    handleShowOnboarding(item._id, item.titulo)
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}

      {orderedItems && !isLoading && (
        <div>
          <div>
            {/* Render the data for the selected card */}
            <div className="flex items-center mb-[22px]">
              <div className="pr-4">
                <button
                  className="pl-4 transform transform-origin-bottom hover:scale-105"
                  onClick={handleClearCardData}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="#000000D9"
                    class="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>
                </button>
              </div>
              <h2 className="text-sc-black font-bold text-[24px] text-left md:text-[32px] mb-[10px]">
                {selectedTitle}
              </h2>
            </div>
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="onboardingList" direction="horizontal">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-wrap gap-12" // Configura el contenedor como flex
                >
                  {sortedItems.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="flex-shrink-0" // Asegura que los elementos no cambien de fila verticalmente
                        >
                          <OnboardingItem
                            onboardingData={item}
                            handleShowOnboarding={() =>
                              handleShowOnboarding(item._id)
                            }
                            onDeleteItem={updateOnboardingData}
                            idCategory={selectedCardId}
                            onboardingDataArray={sortedItems}
                            onGuardar={handleGuardar}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <div
                    className="relative w-[350px] h-[350px] flex items-center justify-center not-draggable"
                    onClick={openModal}
                  >
                    <img src={addIcon} className="cursor-pointer" />
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
      {isContentModalOpen && (
        <ContentModal
          titulo="Nueva Pantalla de Onboarding"
          isEdit={false} // Puedes establecer esto en true si estás editando
          onGuardar={handleGuardar} // Función para manejar el evento "Guardar"
          onDescartar={closeModal} // Función para manejar el evento "Descartar"
          isCamposLlenos={isCamposLlenos}
          editData={null}
          gifImage={null}
          idCategory={selectedCardId}
          onboardingDataArray={selectedCardData}
        />
      )}
    </AdminLayout>
  );
};

export default Onboardings;
