import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  Checkbox,
  Select,
  DatePicker,
  Upload,
  AutoComplete,
  message,
} from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  createCarrouselBanner,
  updateCarrouselBanner,
  uploadCarrouselBannerImage,
  getSections,
  searchContents,
} from "../../api/banners-carrousel";

const { Dragger } = Upload;
const { Option } = Select;

const FormBannerCarrousel = ({ onCancel, banner, onOk }) => {
  const [form] = Form.useForm();
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [isProgrammed, setIsProgrammed] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [desktopFileList, setDesktopFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isInternalLinkDisabled, setIsInternalLinkDisabled] = useState(true);

  useEffect(() => {
    fetchSections();
    if (banner) {
      // Transformar targetDevice en un array de strings para Checkbox.Group
      const devices = [];
      if (banner.targetDevice.ios) devices.push("iOS");
      if (banner.targetDevice.android) devices.push("Android");
      if (banner.targetDevice.pc) devices.push("Pc");

      // Establecer valores en el formulario
      form.setFieldsValue({
        ...banner,
        startDate: banner.startDate ? moment(banner.startDate).local() : null,
        endDate: banner.endDate ? moment(banner.endDate).local() : null,
        haveRoute: banner.haveRoute || false,
        routeType: banner.routeType || null,
        route: banner.route || null,
        targetDevice: devices,
        programBanner: banner.programBanner || false, // Establecer el valor de programación del banner
      });

      setIsLinkActive(banner.haveRoute || false);
      setIsProgrammed(banner.programBanner || false); // Inicializar isProgrammed
      setSelectedDevices(devices);

      // Manejo de la imagen principal
      if (banner.image) {
        setFileList([
          {
            uid: banner.image.id,
            name: "imagen",
            status: "done",
            url: `${banner.image.url}`,
          },
        ]);
      }

      // Manejo de la imagen para desktop
      if (banner.desktopImage) {
        setDesktopFileList([
          {
            uid: banner.desktopImage.id,
            name: "imagen-desktop",
            status: "done",
            url: `${banner.desktopImage.url}`,
          },
        ]);
      }
    } else {
      form.resetFields();
      setFileList([]);
      setDesktopFileList([]); // Limpiar también el estado de desktopImage
      setSelectedDevices([]);
      setIsProgrammed(false);
    }
  }, [banner, form]);

  const fetchSections = async () => {
    const authToken = localStorage.getItem("token");
    try {
      const data = await getSections(authToken);
      setSections(
        data.map((section) => ({ value: section.id, label: section.titulo }))
      );
    } catch (error) {
      console.error("Error al obtener las secciones:", error);
    }
  };

  const handleLinkToggle = (e) => {
    const isLinkActive = e.target.value;
    setIsLinkActive(isLinkActive);

    if (!isLinkActive) {
      // Borrar el valor de los campos dependientes cuando se desactiva
      form.setFieldsValue({
        routeType: null,
        route: null,
        showRouteButton: false, // Desactivar el botón de ruta
      });
      setOptions([]); // Limpiar opciones de búsqueda de ruta
    }
  };

  const handleProgrammedToggle = (e) => {
    const isProgrammed = e.target.value;
    setIsProgrammed(isProgrammed);

    if (!isProgrammed) {
      form.setFieldsValue({
        startDate: null,
        endDate: null,
      });
    }
  };

  const handleDeviceChange = (devices) => {
    setSelectedDevices(Array.isArray(devices) ? devices : []);
    form.setFieldsValue({ targetDevice: devices });

    // Actualizamos las reglas de validación de acuerdo a los dispositivos seleccionados
    form.setFields([
      {
        name: "image",
        rules: [
          {
            required: devices.includes("iOS") || devices.includes("Android"),
            message: "Por favor sube una imagen",
          },
        ],
      },
      {
        name: "desktopImage",
        rules: [
          {
            required: devices.includes("Pc"),
            message: "Por favor sube una imagen para desktop",
          },
        ],
      },
    ]);
  };

  const handleUpload = async ({ file, onSuccess, onError }) => {
    const authToken = localStorage.getItem("token");
    setLoading(true);
    try {
      const uploadedImage = await uploadCarrouselBannerImage(file, authToken);
      setFileList([
        { uid: uploadedImage.id, url: uploadedImage.url, name: file.name },
      ]);
      form.setFieldsValue({ image: uploadedImage.id });
      onSuccess(null, file);
      message.success("Imagen subida con éxito");
    } catch (error) {
      onError(error);
      message.error("Error al subir la imagen");
    } finally {
      setLoading(false);
    }
  };

  const handleDesktopUpload = async ({ file, onSuccess, onError }) => {
    const authToken = localStorage.getItem("token");
    setLoading(true);
    try {
      const uploadedImage = await uploadCarrouselBannerImage(file, authToken);
      setDesktopFileList([
        { uid: uploadedImage.id, url: uploadedImage.url, name: file.name },
      ]);
      form.setFieldsValue({ desktopImage: uploadedImage.id });
      onSuccess(null, file);
      message.success("Imagen subida con éxito");
    } catch (error) {
      onError(error);
      message.error("Error al subir la imagen de desktop");
    } finally {
      setLoading(false);
    }
  };

  const handleRouteTypeChange = (value) => {
    form.setFieldsValue({ route: null });
    setOptions([]);
    form.setFieldsValue({ routeType: value });

    // Desactivar/Activar el campo de "Link/Enlace" si es interno y no tiene Tipo de Usuario
    const userType = form.getFieldValue("userType");
    if (value === "Interno" && !userType) {
      setIsInternalLinkDisabled(true);
    } else {
      setIsInternalLinkDisabled(false);
    }
  };
  const handleUserTypeChange = (e) => {
    const userType = e.target.value;
    form.setFieldsValue({ userType });

    // Si es enlace interno y no hay tipo de usuario, desactiva el input "Link/Enlace"
    const routeType = form.getFieldValue("routeType");
    if (routeType === "Interno" && !userType) {
      setIsInternalLinkDisabled(true);
    } else {
      setIsInternalLinkDisabled(false);
    }

    // Resetea el valor del campo "Link/Enlace" cuando cambia el tipo de usuario
    form.setFieldsValue({ route: null });
    setOptions([]);
  };

  const searchContentsAsync = async (text) => {
    const authToken = localStorage.getItem("token");
    const { userType, routeType } = form.getFieldsValue();
    if (routeType !== "Interno") return;

    const body = {
      keyword: text,
      isPremium: userType === "Premium",
    };

    try {
      const data = await searchContents(body, authToken);
      if (data && data.length > 0) {
        setOptions(
          data.map((item) => ({ value: item.id, label: item.titulo }))
        );
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error("Error al buscar enlaces internos:", error);
      message.error("Ocurrió un error al buscar enlaces internos");
    }
  };

  const onFinish = async (values) => {
    const authToken = localStorage.getItem("token");
    const payload = {
      ...values,
      targetDevice: {
        ios: selectedDevices.includes("iOS"),
        android: selectedDevices.includes("Android"),
        pc: selectedDevices.includes("Pc"),
      },
      startDate: values.startDate
        ? values.startDate.format("YYYY-MM-DDTHH:mm:ss")
        : null,
      endDate: values.endDate
        ? values.endDate.format("YYYY-MM-DDTHH:mm:ss")
        : null,
      image: fileList[0]?.uid,
    };

    try {
      if (banner) {
        await updateCarrouselBanner(banner.id, payload, authToken);
        message.success("Banner actualizado con éxito");
      } else {
        await createCarrouselBanner(payload, authToken);
        message.success("Banner creado con éxito");
      }
      form.resetFields();
      setFileList([]);
      onOk();
    } catch (error) {
      console.error("Error al guardar el banner:", error);
      message.error(
        error.response?.data?.error || "Ocurrió un error al guardar el banner"
      );
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ textAlign: "left" }}
    >
      {/* Primera fila */}
      <div style={{ display: "flex", gap: "32px" }}>
        <Form.Item
          name="title"
          label="Título del Banner"
          style={{ flex: 1 }}
          rules={[
            {
              max: 50,
              message: "El título no puede tener más de 50 caracteres",
            },
            {
              validator: (_, value) => {
                if (value && value.trim() !== value) {
                  return Promise.reject(
                    new Error(
                      "El título no puede tener espacios en blanco al inicio o al final"
                    )
                  );
                }
                if (
                  value &&
                  value.charAt(0) !== value.charAt(0).toUpperCase()
                ) {
                  return Promise.reject(
                    new Error("La primera letra debe ser mayúscula")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="section"
          label="Sección"
          style={{ flex: 1 }}
          rules={[{ required: true, message: "Selecciona una sección" }]}
        >
          <Select placeholder="Selecciona una sección">
            {sections.map((section) => (
              <Option key={section.value} value={section.value}>
                {section.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      {/* Segunda fila */}
      <div style={{ display: "flex", gap: "32px" }}>
        <Form.Item
          name="userType"
          label="Tipo de Usuario"
          style={{ flex: 1 }}
          rules={[{ required: true, message: "Selecciona un tipo de usuario" }]}
        >
          <Radio.Group onChange={handleUserTypeChange}>
            <Radio value="Free">Free</Radio>
            <Radio value="Premium">Premium</Radio>
            <Radio value="Ambos">Ambos</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="targetDevice"
          label="Dispositivos"
          style={{ flex: 1 }}
          rules={[
            { required: true, message: "Selecciona al menos un dispositivo" },
          ]}
        >
          <Checkbox.Group
            options={[
              { label: "Web", value: "Pc" },
              { label: "Android", value: "Android" },
              { label: "iOS", value: "iOS" },
            ]}
            onChange={handleDeviceChange}
          />
        </Form.Item>
      </div>

      {/* Cuarta fila - Programación de fechas */}
      <div style={{ display: "flex", gap: "32px" }}>
        <Form.Item
          name="programBanner"
          label="Banner Programado"
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              message: "Seleccione si el banner está programado",
            },
          ]}
        >
          <Radio.Group onChange={handleProgrammedToggle}>
            <Radio value={true}>Sí</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="startDate"
          label="Fecha y hora de inicio"
          style={{ flex: 1 }}
          rules={[
            {
              required: isProgrammed,
              message: "Selecciona una fecha de inicio",
            },
          ]}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            disabled={!isProgrammed}
          />
        </Form.Item>
        <Form.Item
          name="endDate"
          label="Fecha y hora de fin"
          style={{ flex: 1 }}
          rules={[
            { required: isProgrammed, message: "Selecciona una fecha de fin" },
          ]}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            disabled={!isProgrammed}
          />
        </Form.Item>
      </div>

      {/* Campos de ruta */}
      <div style={{ display: "flex", gap: "32px" }}>
        <Form.Item name="haveRoute" label="Contiene Enlace" style={{ flex: 1 }}>
          <Radio.Group onChange={handleLinkToggle}>
            <Radio value={true}>Sí</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="routeType"
          label="Tipo de Enlace"
          style={{ flex: 1 }}
          rules={[
            { required: isLinkActive, message: "Selecciona un tipo de enlace" },
          ]}
        >
          <Select
            disabled={!isLinkActive}
            placeholder="Selecciona un tipo de enlace"
            onChange={handleRouteTypeChange}
          >
            <Option value="Interno">Interno</Option>
            <Option value="Externo">Externo</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="route"
          label="Link/Enlace"
          style={{ flex: 1 }}
          rules={[{ required: isLinkActive, message: "Ingresa el enlace" }]}
        >
          <AutoComplete
            disabled={isInternalLinkDisabled || !isLinkActive}
            options={options}
            onSearch={searchContentsAsync}
            placeholder="Ingresa el enlace o búsqueda"
          />
        </Form.Item>
      </div>

      {/* Enlace y visibilidad */}
      <div style={{ display: "flex", gap: "32px" }}>
        <Form.Item
          name="showRouteButton"
          label="Mostrar Botón de Ruta"
          style={{ flex: 1 }}
        >
          <Radio.Group disabled={!isLinkActive}>
            <Radio value={true}>Sí</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="visible" label="Visible" style={{ flex: 1 }}>
          <Radio.Group>
            <Radio value={true}>Sí</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      <div className="flex gap-8">
        {/* Input de imagen para móviles */}
        {selectedDevices.includes("iOS") ||
        selectedDevices.includes("Android") ? (
          <Form.Item
            name="image"
            label="Selecciona una Imagen"
            style={{ flex: 1 }}
            rules={[{ required: true, message: "Por favor sube una imagen" }]}
          >
            <Dragger
              accept=".jpg,.jpeg,.png"
              customRequest={handleUpload}
              fileList={fileList}
              showUploadList={false}
              className="bg-gray-100 border-dashed border-2 border-gray-300 rounded-lg p-8"
            >
              <div>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined className="text-4xl text-gray-400" />
                </p>
                {fileList.length > 0 ? (
                  <p className="ant-upload-text">
                    Haz clic o arrastra otra imagen para reemplazarla
                  </p>
                ) : (
                  <p className="ant-upload-text">Imagen (JPG, PNG)</p>
                )}
                <p className="ant-upload-hint">
                  Haz clic o arrastra un archivo para subirlo
                </p>
              </div>
            </Dragger>
            {fileList.length > 0 && fileList[0].url && (
              <div style={{ marginTop: "10px", textAlign: "center" }}>
                <a
                  href={fileList[0].url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#1890ff" }}
                >
                  Ver Imagen Subida
                </a>
              </div>
            )}
          </Form.Item>
        ) : null}

        {/* Input de imagen para desktop */}
        {selectedDevices.includes("Pc") ? (
          <Form.Item
            name="desktopImage"
            label="Selecciona una Imagen para Desktop"
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
                message: "Por favor sube una imagen para desktop",
              },
            ]}
          >
            <Dragger
              accept=".jpg,.jpeg,.png"
              customRequest={handleDesktopUpload}
              fileList={desktopFileList}
              showUploadList={false}
              className="bg-gray-100 border-dashed border-2 border-gray-300 rounded-lg p-8"
            >
              {desktopFileList.length > 0 && desktopFileList[0].url ? (
                <div style={{ textAlign: "center" }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined className="text-4xl text-gray-400" />
                  </p>
                  <p className="ant-upload-text">
                    Imagen subida con éxito para Desktop:
                  </p>
                  <p className="ant-upload-hint">
                    Haz clic o arrastra otra imagen para reemplazarla
                  </p>
                </div>
              ) : (
                <div>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined className="text-4xl text-gray-400" />
                  </p>
                  <p className="ant-upload-text">Imagen Desktop (JPG, PNG)</p>
                  <p className="ant-upload-hint">
                    Haz clic o arrastra un archivo para subirlo
                  </p>
                </div>
              )}
            </Dragger>
            {desktopFileList.length > 0 && desktopFileList[0].url && (
              <div style={{ marginTop: "10px", textAlign: "center" }}>
                <a
                  href={desktopFileList[0].url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#1890ff" }}
                >
                  Ver Imagen Subida para Desktop
                </a>
              </div>
            )}
          </Form.Item>
        ) : null}
      </div>

      {/* Botones de acción alineados a la derecha */}
      <Form.Item style={{ textAlign: "right" }}>
        <Button
          htmlType="button"
          style={{ border: "0", fontSize: "16px", marginRight: "50px" }}
          onClick={onCancel}
        >
          Cancelar
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          style={{
            border: "0",
            borderRadius: "20px",
            padding: "10px 30px",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "16px",
            color: "#000",
            width: "170px",
          }}
        >
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormBannerCarrousel;
