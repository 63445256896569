import * as React from "react";
import { useState } from "react";
import { Modal, Button, Upload, message, Typography } from "antd";
import { ExclamationCircleOutlined, InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import SCButton from "../components/SCButton";
import { navigate } from "gatsby";
import { serverUri } from "../api/serverUri";

const { Text } = Typography;

const ImportCsvModal = ({ showModal, hideModal, stateModal }) => {
  const [state, setState] = useState({ file: null });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [controller, setController] = useState(null);
  const fileInputRef = React.useRef(null);

  const handleChange = (event) => {
    console.log(
      "FileUpload.handleChange event.target.files",
      event.target.files
    );
    setError("")
    setState({ file: event.target.files[0] });
  };

  const handleSubmit = async () => {
    
    if(controller){
      controller.abort();
    }

    const newController = new AbortController();
    setController(newController);

    console.log(state.file);
    if(state.file === null){
      setError('No se seleccionó ningún archivo.')
    }else{
      setIsLoading(true);
    const data = new FormData();
    data.append("files", state.file);

      const upload_res = await axios({
        method: "POST",
        url: `${serverUri}/import/users`,
        data:data,
         signal: newController.signal,
        //headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((response) => {
          console.log("Respuesta del servidor");
          console.log(response);
          if (response.status === 400) {
            setError(response.data.message);
          }
          if (response.status === 200) {
            hideModal();
            navigate(`/suscriptores/bitacora/${response.data.id}`);
          }
        })
        .catch((error) => {
          if(axios.isCancel(error)){
            console.log('La promesa fue cancelada')
          }else{
            console.log(error);
          }
        })
        .finally(() => {
          console.log("Se ha terminado la promesa ");
          setIsLoading(false);
          setState();
          setState({ file: null });
          setController(null)
          console.log("--"+controller)
        });
      console.log("upload_res", upload_res);

    }
    

      // const upload_res = await axios({
      //   method: "POST",
      //   url: `${serverUri}/import/users`,
      //   data:data,
      //   signal: controller.signal,
      //   //headers: { 'Content-Type': 'multipart/form-data' },
      // })

  };

  const props = {
    name: "file",
    accept: ".csv",
    type: "file",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        //console.log(info.file, info.fileList);
        console.log(info);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleCancel = () =>{
    if(controller){
      controller.abort()
      setController(null)
      
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    console.log(state.file)
    setState({file: null});
    setError("")
    hideModal()
  }

  return (
    <>
      <SCButton type="primary" onClick={showModal}>
        Importar desde CSV
      </SCButton>
      <Modal
        title="Importar usuarios desde Archivo CSV"
        visible={stateModal}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText="Subir archivo"
        cancelText="Cancelar carga"
        confirmLoading={isLoading}
      >
        <div>
          <input onChange={handleChange} type="file" ref={fileInputRef}  />
        </div>
        <div>{error ? <Text type="danger">{error}</Text> : null}</div>

        {/* <Dragger {...props} onChange={handleChange}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </Dragger> */}
      </Modal>
    </>
  );
};

export default ImportCsvModal;
