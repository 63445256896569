import React from "react";
import { Button, Space } from "antd";

export const columnsNotifications = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 10,
    render: (text) => <p className="text-xs">{text}</p>,
    sortDirections: ["ascend", "descend"],
    sorter: (a, b) => {
      if (a.id !== undefined && a.id !== null) {
        if (b.id !== undefined && b.id !== null) {
          return a.id.toString().localeCompare(b.id.toString());
        } else {
          return a.id.toString().localeCompare("Sin Respuesta");
        }
      } else {
        if (b.id !== undefined && b.id !== null) {
          return "Sin Respuesta".localeCompare(b.id.toString());
        } else {
          return "Sin Respuesta".localeCompare("Sin Respuesta");
        }
      }
    },
  },
  {
    title: "Fecha de Creación",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 50,
    render: (text) => <p className="text-xs">{text}</p>,
    sortDirections: ["ascend", "descend"],
    sorter: (a, b) => {
      if (a.createdAt !== undefined && a.createdAt !== null) {
        if (b.createdAt !== undefined && b.createdAt !== null) {
          return a.createdAt.toString().localeCompare(b.createdAt.toString());
        } else {
          return a.createdAt.toString().localeCompare("Sin Respuesta");
        }
      } else {
        if (b.createdAt !== undefined && b.createdAt !== null) {
          return "Sin Respuesta".localeCompare(b.createdAt.toString());
        } else {
          return "Sin Respuesta".localeCompare("Sin Respuesta");
        }
      }
    },
  },
  {
    title: "Fecha y hora de programación",
    dataIndex: "scheduledTime",
    key: "scheduledTime",
    width: 50,
    render: (text) => <p className="text-xs">{text}</p>,
    sortDirections: ["ascend", "descend"],
    sorter: (a, b) => {
      if (a.scheduledTime !== undefined && a.scheduledTime !== null) {
        if (b.scheduledTime !== undefined && b.scheduledTime !== null) {
          return a.scheduledTime
            .toString()
            .localeCompare(b.scheduledTime.toString());
        } else {
          return a.scheduledTime.toString().localeCompare("Sin Respuesta");
        }
      } else {
        if (b.scheduledTime !== undefined && b.scheduledTime !== null) {
          return "Sin Respuesta".localeCompare(b.scheduledTime.toString());
        } else {
          return "Sin Respuesta".localeCompare("Sin Respuesta");
        }
      }
    },
  },
  {
    title: "Título de notificación",
    dataIndex: "title",
    key: "title",
    width: 120,
    render: (text) => <p className="text-xs">{text}</p>,
    sortDirections: ["ascend", "descend"],
    sorter: (a, b) => {
      if (a.title !== undefined && a.title !== null) {
        if (b.title !== undefined && b.title !== null) {
          return a.title.toString().localeCompare(b.title.toString());
        } else {
          return a.title.toString().localeCompare("Sin Respuesta");
        }
      } else {
        if (b.title !== undefined && b.title !== null) {
          return "Sin Respuesta".localeCompare(b.title.toString());
        } else {
          return "Sin Respuesta".localeCompare("Sin Respuesta");
        }
      }
    },
  },
  {
    title: "Tipo",
    dataIndex: "type",
    key: "type",
    width: 10,
    render: (text) => <p className="text-xs">{text}</p>,
    sortDirections: ["ascend", "descend"],
    sorter: (a, b) => {
      if (a.type !== undefined && a.type !== null) {
        if (b.type !== undefined && b.type !== null) {
          return a.type.toString().localeCompare(b.type.toString());
        } else {
          return a.type.toString().localeCompare("Sin Respuesta");
        }
      } else {
        if (b.type !== undefined && b.type !== null) {
          return "Sin Respuesta".localeCompare(b.type.toString());
        } else {
          return "Sin Respuesta".localeCompare("Sin Respuesta");
        }
      }
    },
  },
  {
    title: "Actions",
    key: "actions",
    width: 50,
  },
];
