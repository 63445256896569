import * as React from "react";
import { useState } from "react";
import AdminLayout from "../components/AdminLayout";
import { Table, Tag, Space, Button } from "antd";
import { Row, Col } from "antd";
import {
  DeleteOutlined,
  StopOutlined,
  MobileOutlined,
  LockOutlined,
} from "@ant-design/icons";
import ImportCsvModal from "../components/ImportCsvModal";
import { getUsers } from "../api/users";
import SCButton from "../components/SCButton";
import { navigate } from "gatsby";
const columns = [
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
  },
  {
    title: "Apellido",
    dataIndex: "apellido",
    key: "apellido",
  },

  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },

  {
    title: "Membresía",
    dataIndex: "suscription",
    key: "membresía",
  },

  {
    title: "Fecha de suscripción",
    dataIndex: "createdAt",
    key: "suscripción",
  },

  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   key: "status",
  // },

  {
    title: "Acción",
    dataIndex: "acción",
    key: "acción",
    render: () => (
      <Space size="middle">
        <StopOutlined />

        <LockOutlined />
        <MobileOutlined />

        <DeleteOutlined />
      </Space>
    ),
  },
];

const rowSelection = {
  // onChange: (selectedRowKeys, selectedRows) => {
  //   console.log(
  //     `selectedRowKeys: ${selectedRowKeys}`,
  //     "selectedRows: ",
  //     selectedRows
  //   );
  // },
  onSelect: (record, selected, selectedRows, nativeEvent) => {
    console.log(`record`);
    console.log(record);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

const Suscriptores = () => {
  const [stateModal, setStateModal] = useState(false);
  const [usersData, setUsersData] = useState();

  const showModal = () => {
    setStateModal(true);
  };

  const hideModal = () => {
    setStateModal(false);
  };

  React.useEffect(() => {
    getUsers()
      .then((response) => {
        setUsersData(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(()=>console.log('termino'));

    //// cargar los usuarios
  }, []);

  const showUser = (email) => {
    navigate(`/suscriptores/${email}`);
  };

  return (
    <AdminLayout>
      <Row justify="start">
        <Col span={6}>
          <h3 className="mb-8 text-sc-black font-bold text-[32px] ">
            Cargas Masivas
          </h3>
        </Col>
        <Col span={18}>
          <Row justify="end" gutter={8}>
            <Col>
              <SCButton>Exportar a CSV</SCButton>
            </Col>
            <Col>
              <ImportCsvModal
                showModal={showModal}
                hideModal={hideModal}
                stateModal={stateModal}
              />
            </Col>
            <Col>
              <SCButton>Agregar Suscriptor</SCButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className="mt-4 cursor-pointer"
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        dataSource={usersData}
        columns={columns}
        pagination={{showSizeChanger: true}}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              showUser(
                event.target.parentElement.childNodes[4].previousSibling
                  .innerText
              );
            }, // click row
          };
        }}
      />
    </AdminLayout>
  );
};

export default Suscriptores;
