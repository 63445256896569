import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import AdminLayout from "../components/AdminLayout";
import { Button, Input, message, Select, Table, Breadcrumb } from "antd";
import {
  getCarrouselBanners,
  deleteCarrouselBanner,
  toggleVisibilityBanner,
  getSections,
} from "../api/banners-carrousel";
import {
    SearchOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowLeftOutlined,
  } from "@ant-design/icons";
import FormBannerCarrousel from "../components/banners-carrousel/FormBannerCarrousel";

const { Option } = Select;

export const CarrouselBanners = () => {
  const [loading, setLoading] = useState(true);
  const [dataBanners, setDataBanners] = useState([]);
  const [sections, setSections] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [userTypeFilter, setUserTypeFilter] = useState(null);
  const [sectionFilter, setSectionFilter] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingBanner, setEditingBanner] = useState(null);

  const fetchSections = async () => {
    const authToken = localStorage.getItem("token");
    try {
      const data = await getSections(authToken);
      setSections(
        data.map((section) => ({ value: section.id, label: section.titulo }))
      );
    } catch (error) {
      console.error("Error al obtener las secciones:", error);
      message.error("Ocurrió un error al obtener las secciones");
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar este banner?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#90E6D3",
      cancelButtonColor: "#6e7881",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sí, eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const authToken = localStorage.getItem("token");

        await deleteCarrouselBanner(id, authToken);
        message.success("Banner eliminado con éxito");
        fetchBanners();
      }
    });
  };

  const handleToggleVisibility = async (id) => {
    try {
      const authToken = localStorage.getItem("token");
      await toggleVisibilityBanner(id, authToken);
      message.success("Visibilidad del banner actualizada correctamente");
      fetchBanners();
    } catch (error) {
      console.error("Error al cambiar la visibilidad:", error);
      
      // Verifica si el error tiene una respuesta válida y muestra la propiedad "error"
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "Ocurrió un error al cambiar la visibilidad del banner";
          
      message.error(errorMessage);
    }
  };
  

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const handleDeviceChange = (value) => {
    setDeviceFilter(value);
  };

  const handleUserTypeChange = (value) => {
    setUserTypeFilter(value);
  };

  const handleSectionChange = (value) => {
    setSectionFilter(value);
  };

  const fetchBanners = async () => {
    try {
      const authToken = localStorage.getItem("token");
      setLoading(true);
      const data = await getCarrouselBanners(authToken);
      setDataBanners(Array.isArray(data.data) ? data.data : []);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los banners:", error);
      message.error("Ocurrió un error al obtener los banners");
      setDataBanners([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSections(); // Llamada para cargar las secciones al iniciar el componente
    fetchBanners();
  }, []);

  const filteredBanners = dataBanners.filter((banner) => {
    const matchesSearchText = banner.title
      ? banner.title.toLowerCase().includes(searchText)
      : searchText === ""; // Si no hay `title`, solo lo muestra si no hay texto de búsqueda
  
    const matchesDevice = deviceFilter
      ? banner.targetDevice && banner.targetDevice[deviceFilter.toLowerCase()]
      : true;
  
    const matchesUserType = userTypeFilter
      ? banner.userType === userTypeFilter
      : true;
  
    const matchesSection = sectionFilter
      ? sections.some(
          (section) =>
            section.value === sectionFilter && section.value === banner.section
        )
      : true;
  
    return matchesSearchText && matchesDevice && matchesUserType && matchesSection;
  });
  


  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      responsive: ['lg'], // Oculta la columna en pantallas pequeñas
    },
    {
      title: "Fecha de Creación",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (date) => new Date(date).toLocaleDateString(),
      responsive: ['md'], // Oculta en pantallas medianas y pequeñas
    },
    {
      title: "Título del Banner",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title), // Añadimos el sorter
      // No agregamos 'responsive' aquí para que siempre esté visible
    },
    {
      title: "Dispositivos",
      dataIndex: "targetDevice",
      render: (device) => {
        const formattedDevices = ["web", "iOS", "Android"].filter((key) => {
          if (key === "web") return device["pc"];
          return device[key.toLowerCase()];
        });
        return formattedDevices.join(", ");
      },
      sorter: (a, b) => a.targetDevice.pc - b.targetDevice.pc, // Seguimos usando pc internamente para ordenar
      responsive: ['xl'], // Mostrar en pantallas pequeñas y mayores
    },
    {
      title: "Tipo de Usuario",
      dataIndex: "userType",
      sorter: (a, b) => a.userType.localeCompare(b.userType), // Añadimos el sorter
      responsive: ['md'], // Mostramos a partir de pantallas medianas
    },
    {
      title: "Visibilidad",
      dataIndex: "visible",
      render: (visible) =>
        visible ? (
          <EyeOutlined style={{ color: "green" }} />
        ) : (
          <EyeInvisibleOutlined style={{ color: "red" }} />
        ),
      responsive: ['lg'], // Ocultar en pantallas pequeñas
    },
    {
      title: "Acciones",
      render: (_, record) => (
        <div className="flex space-x-2">
          <Button
            icon={record.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onClick={() => handleToggleVisibility(record.id)}
            style={{ color: record.visible ? "green" : "red" }}
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditingBanner(record); // Establecer el banner en edición
              setIsFormVisible(true); // Mostrar el formulario de edición
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
            danger
          />
        </div>
      ),
      // No agregamos 'responsive' para que siempre esté visible
    },
  ];
  
  

  return (
    <AdminLayout>
    {!isFormVisible ? (
      <section className="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl p-10">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Carrusel de banners</h1>

          <Button
            className="shadow-2xl cursor-pointer text-lg text-black hover:text-black"
            type="primary"
            style={{
              border: "none",
              borderRadius: "44px",
              backgroundColor: "#00CEA9",
              fontSize: "14px",
              color: "black",
              width: "200px",
              height: "36px",
              boxShadow: "0 10px 10px -12px rgba(0, 0, 0, 1)",
            }}
            onClick={() => {
                setEditingBanner(null);
                setIsFormVisible(true);
              }}
          >
            Nuevo Banner
          </Button>
        </div>

        <div className="flex gap-4 mb-6">
          <Input
            placeholder="Buscar por título..."
            value={searchText}
            onChange={handleSearch}
            style={{ width: "200px" }}
            suffix={<SearchOutlined />}
          />
          <Select
            placeholder="Dispositivo"
            onChange={handleDeviceChange}
            style={{ width: "150px" }}
            allowClear
          >
            <Option value="Pc">Web</Option>
            <Option value="Android">Android</Option>
            <Option value="iOS">iOS</Option>
          </Select>
          <Select
            placeholder="Tipo de Usuario"
            onChange={handleUserTypeChange}
            style={{ width: "150px" }}
            allowClear
          >
            <Option value="Free">Free</Option>
            <Option value="Premium">Premium</Option>
          </Select>
          <Select
            placeholder="Sección"
            onChange={handleSectionChange}
            style={{ width: "150px" }}
            allowClear
          >
            {sections.map((section) => (
              <Option key={section.value} value={section.value}>
                {section.label}
              </Option>
            ))}
          </Select>
        </div>

        <Table
          columns={columns}
          dataSource={filteredBanners}
          loading={loading}
          rowKey="id"
          pagination={{ pageSize: 10 }}
        />
      </section>
    ) : (
<>
  <Breadcrumb className="mb-6" separator=">">
    <Breadcrumb.Item>
      <Button
        type="link"
        icon={<ArrowLeftOutlined style={{ marginRight: "8px" }} />}
        onClick={() => setIsFormVisible(false)}
        style={{ padding: 0 }}
      >
        Banners publicitarios {editingBanner ? "/ Editar banner" : "/ Nuevo banner"}
      </Button>
    </Breadcrumb.Item>
  </Breadcrumb>
  <section className="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl p-12 mb-8 mt-4">
    <h2 className="text-3xl text-left font-bold mb-12">
      {editingBanner ? "Editar Banner" : "Nuevo Banner"}
    </h2>
    <FormBannerCarrousel
      banner={editingBanner}
      onCancel={() => setIsFormVisible(false)}
      onOk={() => {
        setIsFormVisible(false);
        fetchBanners();
      }}
    />
  </section>
</>

    )}
  </AdminLayout>
  );
};

export default CarrouselBanners;
