
const serverUri = "https://cms.shiftmexico.com";
//const serverUri = "https://shift-cms-dev.ironbit.net";

const getNamesOnboarding = () => {
  return new Promise((resolve, reject) => {
    fetch(`${serverUri}/onboardings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getItemsOnboarding = (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${serverUri}/onboarding/${id}/pantallas`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const addOnboarding = (data, authToken) => {
    return fetch(`${serverUri}/pantallas-de-onboardings`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
      body: data, // Utiliza la propiedad "body" en lugar de "data"
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("La solicitud falló");
        }
        return response.json(); // Parsea la respuesta JSON
      })
      .then((data) => {
        return data; // Resuelve la promesa con los datos
      })
      .catch((error) => {
        console.log("Error al actualizar: ", error);
        throw error; // Rechaza la promesa en caso de error
      });
  };


  const updateOnboarding = (data, authToken, id) => {
    return fetch(`${serverUri}/pantallas-de-onboardings/${id}`, {
      method: 'PUT',
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
      body: data, // Utiliza la propiedad "body" en lugar de "data"
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("La solicitud falló");
        }
        return response.json(); // Parsea la respuesta JSON
      })
      .then((data) => {
        return data; // Resuelve la promesa con los datos
      })
      .catch((error) => {
        console.log("Error al actualizar: ", error);
        throw error; // Rechaza la promesa en caso de error
      });
  };

const deleteOnboardingPantalla = (id, authToken) => {
  return new Promise((resolve, reject) => {
    fetch(`${serverUri}/pantallas-de-onboardings/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  getNamesOnboarding,
  getItemsOnboarding,
  addOnboarding,
  updateOnboarding,
  deleteOnboardingPantalla, // Agregamos la nueva función
};
