import React from "react";
import { navigate } from "gatsby";
import { getUser, logout } from "../services/auth";
import AdminLayout from "../components/AdminLayout";
const Profile = () => (
  <AdminLayout>
    <h1>¡Bienvenido!</h1>
    <ul>
      <li>E-mail: {getUser().email}</li>
    </ul>
    <a
      href="/"
      onClick={(event) => {
        event.preventDefault();
        logout(() => navigate(`/app/login`));
      }}
    >
      Cerrar sesión
    </a>
  </AdminLayout>
);
export default Profile;
