import React from "react";
import { handleLogin, isLoggedIn } from "../services/auth";
import logo from "../images/logo_black.png";
import {
  MailOutlined,
  LockOutlined,
  CheckOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Form, Input, Button, Tooltip } from "antd";

import Profile from "./Profile";

const Login = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return isLoggedIn() ? (
    <Profile />
  ) : (
    <div className="bg-login-bg flex md:pr-32 p-0 justify-center md:justify-end h-screen">
      <div className=" md:w-[450px] w-5/6 h-5/6 border-2 bg-white relative my-auto rounded-lg px-5 pt-8 pb-4 overflow-y-auto">
        <Form onFinish={onFinish} form={form} onFinishFailed={onFinishFailed}>
          <img
            className="m-auto mb-8"
            src={logo}
            alt="logo"
            width="80"
            height="32"
          />
          <h3 className="mb-8 text-sc-black font-bold text-[32px] ">
            Iniciar sesión
          </h3>
          <div className="text-center">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tu email",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Correo electrónico"
                prefix={<MailOutlined className="site-form-item-icon" />}
                suffix={
                  <Tooltip>
                    <CheckOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </div>

          <div className="text-center">
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tu contraseña",
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Contraseña"
                prefix={<LockOutlined className="site-form-item-icon" />}
                suffix={
                  <Tooltip title="Extra information">
                    <EyeOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </div>
          <div className="text-center text-slate-600 mb-8 cursor-pointer">
            <p>¿Olvidaste tu contraseña?</p>
          </div>
          <Form.Item>
            <div className=" text-center w-[250px] m-auto">
              <Button type="primary" htmlType="submit">
                Iniciar sesión
              </Button>
              {/* <SCButton onClick={changeLoginStatus}>Iniciar Sesión</SCButton> */}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;

// class Login extends React.Component {
//   state = {
//     username: ``,
//     password: ``,
//   };
//   handleUpdate = (event) => {
//     this.setState({
//       [event.target.name]: event.target.value,
//     });
//   };
//   handleSubmit = (event) => {
//     event.preventDefault();
//     handleLogin(this.state);
//   };
//   render() {
//     if (isLoggedIn()) {
//       navigate(`/app/profile`);
//     }
//     return (
//       <>
//         <h1>Log in</h1>
//         <form
//           method="post"
//           onSubmit={(event) => {
//             this.handleSubmit(event);
//             navigate(`/app/profile`);
//           }}
//         >
//           <label>
//             Email
//             <input type="email" name="email" onChange={this.handleUpdate} />
//           </label>
//           <label>
//             Password
//             <input
//               type="password"
//               name="password"
//               onChange={this.handleUpdate}
//             />
//           </label>
//           <input type="submit" value="Log In" />
//         </form>
//       </>
//     );
//   }
// }
// export default Login;
