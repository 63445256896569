import React, { useState } from "react";
import editIcon from "../../images/icon_edit.svg";
import deleteIcon from "../../images/icon_delete.svg";
import ConfirmationModal from "./ConfirmationModal";
import ContentModal from "./ContentModal";
import { deleteOnboardingPantalla, updateOnboarding } from "../../api/onboarding";

const OnboardingItem = ({ onboardingData, onDeleteItem, idCategory, onboardingDataArray, onGuardar }) => {
  const { titulo, descripcion, gif } = onboardingData;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  
  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

const handleDeleteConfirmation = (confirmed) => {
  if (confirmed) {
    const authToken = localStorage.getItem("token");
    const deletedPriority = onboardingData.priority; // 1. Registra la prioridad de la tarjeta eliminada

    deleteOnboardingPantalla(onboardingData.id, authToken)
      .then((data) => {
        // 2. Realiza la eliminación de la tarjeta

        // 3. Actualiza las prioridades de las tarjetas restantes
        const updatedCards = onboardingDataArray.map((card) => {
          if (card.idCategory === idCategory && card.priority > deletedPriority) {
            card.priority--; // Reduce la prioridad en uno
          }
          return card;
        });

        // 4. Realiza una solicitud PUT para actualizar las tarjetas con las nuevas prioridades
        updatedCards.forEach((card) => {
          if (card.id == onboardingData.id || card.priority < onboardingData.priority){
            return;
          }
          // Realiza una solicitud PUT para actualizar la tarjeta con la nueva prioridad
          // Utiliza la función y el endpoint adecuados para actualizar la tarjeta en tu API
          // Asegúrate de enviar la nueva prioridad en los datos de la tarjeta
          const formData = new FormData();
          formData.append(
            "data",
            `{"priority": ${card.priority -1}}`
          );
          updateOnboarding(formData , authToken,  card.id)
            .then((response) => {
              // Maneja la respuesta exitosa del servidor aquí
            })
            .catch((error) => {
              console.log("Error al actualizar la tarjeta con ID: " + card.id, error);
            });
        });

        // Call the onDeleteItem function with the deleted item's ID
        onDeleteItem(onboardingData.id);
      })
      .catch((error) => {
        console.log(error);
      });

    setIsDeleteModalOpen(false);
  }


    setIsDeleteModalOpen(false);
  };


  const openModal = () => {
    setIsEditMode(true);
    setEditData(onboardingData); // Guardar los datos de edición
    setModalVisible(true);
  };
  

  const closeModal = () => {
    setModalVisible(false);
    onGuardar();
  };

  const isCamposLlenos = () => {
    // Implementa la lógica para verificar si los campos están llenos aquí
    return true; // Cambia esto según tus requisitos
  };

  return (
    <div className="relative w-[350px] h-[350px] rounded-[2rem] overflow-hidden shadow-lg">
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#00CEA9] to-black"></div>
      <div className="absolute top-0 left-0 p-4 h-full w-full flex flex-col justify-between">
        <div>
          <h3 className="text-white text-[28px] font-bold text-start truncate-multiline">
            {titulo}
          </h3>
          <p className="text-white text-[18px] text-start truncate-multiline-2 h-[43.19px] mb-3">
            {descripcion.length > 80
              ? `${descripcion.substring(0, 80)}...`
              : descripcion}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <img
            src={gif?.url}
            alt="Gif"
            className="max-w-[318px] h-[150px] object-cover object-center"
          />
          <div className="flex justify-between pb-4 pt-4 gap-4">
            {/* Botones */}
            <button
              className="px-4 py-2 rounded-full"
              onClick={handleDeleteClick}
            >
              <img
                src={deleteIcon}
                alt="Imagen"
                className="h-[40px] w-[40px]"
              />
            </button>
            <button className="px-4 py-2 rounded-full">
              <img
                src={editIcon}
                alt="Imagen"
                className="h-[40px] w-[40px]"
                onClick={openModal}
              />
            </button>
          </div>
        </div>
      </div>
      {isDeleteModalOpen && (
        <ConfirmationModal
          pregunta="¿Eliminar Pantalla de Onboarding?"
          descripcion="¿Estás seguro de que deseas eliminar esta pantalla de Onboarding?"
          textoBotonConfirmar="Eliminar"
          textoBotonCancelar="Cancelar"
          onConfirm={handleDeleteConfirmation}
        />
      )}
      {modalVisible && (
        <ContentModal
          titulo="Editar Pantalla de Onboarding"
          isEdit={isEditMode} // Puedes establecer esto en true si estás editando
          onGuardar={closeModal} // Función para manejar el evento "Guardar"
          onDescartar={closeModal} // Función para manejar el evento "Descartar"
          isCamposLlenos={isCamposLlenos}
          editData={editData}
          gifImage={onboardingData.gif}
          idCategory={idCategory}
        />
      )}
    </div>
  );
};

export default OnboardingItem;
