import React, { useState, useEffect } from "react";
import AdminLayout from "../components/AdminLayout";
import { getCuestionario } from "../api/cuestionario";
import PreguntaCuestionario from "../components/cuestionario/PreguntaCuestionario";
import { deletePergunta, deleteRespuesta } from "../api/cuestionario";

const Cuestionario = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [cuestionarioData, setCuestionarioData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [preguntaToDelete, setPreguntaToDelete] = useState(null);
  const authToken = localStorage.getItem("token");
  const handleDeletePregunta = async (indexPregunta, idPregunta) => {
    // Verificar si hay una pregunta para eliminar
    if (idPregunta !== null) {
      // Abrir el modal de confirmación
      setModalOpen(true);
      setPreguntaToDelete({ indexPregunta, idPregunta });
    } else {
      // Caso donde la pregunta es nueva y no está en la base de datos
      const nuevoCuestionarioData = cuestionarioData.filter(
        (_, index) => index !== indexPregunta
      );
      setCuestionarioData(nuevoCuestionarioData);
    }
  };

  const handleConfirmDelete = async () => {
    const { indexPregunta, idPregunta } = preguntaToDelete;
    try {
      // Aquí asumimos que cada pregunta en tu cuestionarioData tiene un campo "respuestas"
      const respuestas = cuestionarioData[indexPregunta].respuestas;

      // Eliminar todas las respuestas asociadas
      for (const respuesta of respuestas) {
        await deleteRespuesta(respuesta.id, authToken);
      }

      // Eliminar la pregunta
      await deletePergunta(idPregunta, authToken);

      // Recargar los datos para reflejar los cambios
      fetchData();
    } catch (error) {
      console.error("Error al eliminar pregunta: ", error);
    } finally {
      // Cerrar el modal después de la confirmación
      setModalOpen(false);
    }
  };

  const handleCancelDelete = () => {
    // Cerrar el modal sin eliminar la pregunta
    setModalOpen(false);
    setPreguntaToDelete(null);
  };

  const handleAddPregunta = () => {
    const nuevaPregunta = {
      pregunta: "Sin pregunta",
      respuestas: [
        { contenido: "Sin respuesta", categoria: "Power", id: null },
        { contenido: "Sin respuesta", categoria: "Balance", id: null },
        { contenido: "Sin respuesta", categoria: "Sport", id: null },
        { contenido: "Sin respuesta", categoria: "Body", id: null },
        { contenido: "Sin respuesta", categoria: "Fun", id: null },
      ],
      id: null, // O asigna un ID único si es necesario
    };

    // Actualiza el estado para incluir la nueva pregunta
    setCuestionarioData([...cuestionarioData, nuevaPregunta]);
  };

  const fetchData = async () => {
    try {
      // Simula una carga de datos
      setIsLoading(true);

      const response = await getCuestionario();
      const cuestionario = response.map((pregunta) => {
        const respuestas = pregunta.respuestas.map((respuesta) => {
          return {
            contenido: respuesta.contenido,
            categoria: respuesta.categoria,
            id: respuesta.id,
          };
        });

        return {
          pregunta: pregunta.titulo,
          respuestas: respuestas,
          id: pregunta.id,
        };
      });

      setCuestionarioData(cuestionario);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false); // Cambia el estado a false en caso de error
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AdminLayout>
      <div>
        <h1 className="mb-8 text-sc-black font-bold text-[24px] text-left md:text-[32px]">
          Cuestionario de gustos
        </h1>
        {isLoading ? (
          <p>Cargando...</p>
        ) : (
          <>
            {cuestionarioData && cuestionarioData.length > 0 ? (
              cuestionarioData.map((preguntaData, iteracion) => (
                <PreguntaCuestionario
                  key={iteracion} // Agrega una clave única para cada elemento mapeado
                  iteracion={iteracion}
                  preguntaData={preguntaData}
                  fetchData={fetchData}
                  handleDeletePregunta={handleDeletePregunta}
                  authToken={authToken}
                />
              ))
            ) : (
              <p>No hay preguntas disponibles.</p>
            )}

            <button onClick={handleAddPregunta} className="">
              Añadir Pregunta
            </button>
          </>
        )}
      </div>
      {/* Modal de confirmación */}
      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-[60]">
          {/* Máscara obscura detrás del modal */}
          <div className="fixed inset-0 bg-black opacity-50 z-40" />
          <div className="bg-white w-[522px] p-4 rounded-md shadow-lg flex flex-col justify-between relative z-50">
            <div className="px-16">
              <h2 className="font-bold text-2xl text-[#002821]">¿Estás seguro de que deseas eliminar esta pregunta?</h2>
            </div>
            <div className="flex justify-between px-8">
              <button
                onClick={handleCancelDelete}
                className="bg-[#353535] text-white p-2 rounded-full mr-2 px-16 shadow-md"
              >
                Cancelar
              </button>
              <button
                onClick={handleConfirmDelete}
                className="bg-[#00CEA9] text-[#002821] p-2 rounded-full px-16 shadow-md"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default Cuestionario;
