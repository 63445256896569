import React, { useState } from "react";

const ConfirmationModal = ({
  pregunta,
  descripcion,
  textoBotonConfirmar,
  textoBotonCancelar,
  onConfirm,
}) => {
  const handleConfirm = () => {
    onConfirm(true);
  };

  const handleCancel = () => {
    onConfirm(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[60]">
      {/* Máscara obscura detrás del modal */}
      <div className="fixed inset-0 bg-black opacity-50 z-40" />

      <div className="bg-white w-[522px] p-4 rounded-md shadow-lg flex flex-col justify-between relative z-50">
        <div className="px-16">
          <h2 className="font-bold text-2xl text-[#002821]">{pregunta}</h2>
          <p className="mt-2 text-sm text-black">{descripcion}</p>
        </div>
        <div className="flex justify-between px-8">
          <button
            onClick={handleCancel}
            className="bg-[#353535] text-white p-2 rounded-full mr-2 px-16 shadow-md"
          >
            {textoBotonCancelar}
          </button>
          <button
            onClick={handleConfirm}
            className="bg-[#00CEA9] text-[#002821] p-2 rounded-full px-16 shadow-md"
          >
            {textoBotonConfirmar}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
